import React from 'react'
import { useTranslation } from 'react-i18next'

import {
  Typography,
  Tab,
  TextInput,
  Switch,
  Grid,
  GridItem,
  styled,
  Button,
  Dropdown,
} from '@deliveryhero/armor'

import { TabView } from '../../../components/armor-overrides'
import { Field, KeyedResponseData, Language } from '../../../global/types'
import { SUPPORTED_VALIDATIONS } from '../../../utils/field-validations'
import { snakeToTitleCase } from '../../../utils/strings'
import { DenormalizedLandingPage } from '../selectors'

import { MainFieldsSwitch } from './MainFieldsSwitch/MainFieldsSwitch'
import {
  CenteredTabs,
  GridHeader,
  RowAlignedGrid,
  Title,
  DescriptionBody,
  DescriptionNote,
} from './shared-components'

const WideTabView = styled(TabView)`
  width: 100%;
`

const AGE = 'age'
const STRING_FIELD = 'stringField'

type IProps = {
  landingPage: DenormalizedLandingPage
  handleLandingPageChange: (e: React.ChangeEvent<HTMLInputElement>) => void
  setSelectedLanguage: (index: number) => void
  selectedLanguage: number
  availableLanguages: Language[]
  handleFieldChange: (
    _: string,
    id: string,
    property: string,
    value: string | { validations: string[] } | boolean,
  ) => void
  onAddNewField: (selectedLanguage: number) => void
  handleChanges: (changes: KeyedResponseData) => void
}

export const GeneralTab: React.FC<IProps> = ({
  landingPage,
  handleLandingPageChange,
  setSelectedLanguage,
  selectedLanguage,
  availableLanguages,
  handleFieldChange,
  onAddNewField,
  handleChanges,
}) => {
  const [t] = useTranslation()

  const setFields = (fields: any[]) => {
    handleChanges({
      landingPageContents: {
        [landingPage.landingPageContents[selectedLanguage].id]: {
          restOperation: 'PATCH',
          fields,
        },
      },
    })
  }

  const fieldComponents = {
    // eslint-disable-next-line react/no-unstable-nested-components
    age: (field: { text: any; name: string; id: string }) => (
      <Typography>
        {t('Settings.General.TheApplicantMustBeOver')}
        {
          <TextInput
            style={{
              maxWidth: '4rem',
              marginBottom: '1.5rem',
              marginRight: 8,
              marginLeft: 8,
            }}
            value={`${field.text}`}
            label={snakeToTitleCase(field.name)}
            onChange={e =>
              handleFieldChange(field.name, field.id, 'text', e.target.value)
            }
          />
        }{' '}
        {t('Settings.General.Old')}
      </Typography>
    ),
    // eslint-disable-next-line react/no-unstable-nested-components
    stringField: (field: { text: any; name: string; id: string }) => (
      <TextInput
        disabled={field.restOperation === 'POST'}
        style={{ marginBottom: '1.5rem' }}
        value={`${field.text}`}
        label={snakeToTitleCase(field.name)}
        onChange={e =>
          handleFieldChange(field.name, field.id, 'text', e.target.value)
        }
      />
    ),
  }

  const validationOptions = SUPPORTED_VALIDATIONS.map(validation => ({
    label: t(`Settings.General.${validation}`),
    value: validation,
  }))

  const onValidationChange =
    (field: Field) => (e: React.ChangeEvent<HTMLInputElement>) => {
      handleFieldChange(field.name, field.id, 'settings', {
        validations: [e.target.value.toString()],
      })
    }

  return (
    <Grid style={{ flexDirection: 'column' }}>
      <GridItem>
        <TextInput
          label={t('Settings.General.ProjectNamePlaceholder')}
          name="name"
          large
          wide
          value={landingPage.name}
          onChange={handleLandingPageChange}
          marginBottom={6}
        />
        <TextInput
          name="baseUrl"
          label={t('Settings.General.BaseUrlPlaceholder')}
          large
          wide
          value={landingPage.baseUrl}
          onChange={handleLandingPageChange}
          marginBottom={3}
        />
        <TextInput
          label={t('Settings.General.LandingPageDomain')}
          name="landingPageDomain"
          large
          wide
          value={landingPage.landingPageDomain}
          onChange={handleLandingPageChange}
          marginBottom={6}
        />
        <TextInput
          label={t('Settings.General.PrivacyPolicyUrl')}
          name="privacyPolicyUrl"
          large
          wide
          value={landingPage.privacyPolicyUrl}
          onChange={handleLandingPageChange}
          marginBottom={6}
        />
        <TextInput
          label={t('Settings.General.CookiePolicyUrl')}
          name="cookiePolicyUrl"
          large
          wide
          value={landingPage.cookiePolicyUrl}
          onChange={handleLandingPageChange}
          marginBottom={6}
        />
        <DescriptionNote paragraph small>
          {t('Settings.General.ThisWillBeTheUrlForLandingPage')}
        </DescriptionNote>

        <Title subSectionTitle>{t('Settings.General.MainFormFields')}</Title>
        <DescriptionBody style={{ marginBottom: '1.5rem' }} paragraph small>
          {t('Settings.General.MainFormFieldsHelper')}
        </DescriptionBody>
      </GridItem>
      <GridItem>
        <MainFieldsSwitch
          handleChanges={handleChanges}
          landingPageContents={landingPage.landingPageContents}
          selectedLanguage={selectedLanguage}
          setSelectedLanguage={setSelectedLanguage}
          handleFieldChange={handleFieldChange}
          onAddNewField={() => onAddNewField(selectedLanguage)}
        />
      </GridItem>
    </Grid>
  )
}
