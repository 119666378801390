import React, { FC } from 'react'

import { styled, Button } from '@deliveryhero/armor'

import { CardWithMenu } from '../../Atoms'
import {
  LandingPageThumbnail,
  LandingPageThumbnailPropsType,
} from '../../Molecules'

type PropsType = {
  thumbnails: LandingPageThumbnailPropsType[]
  createText: string
  createDisabled: boolean
  handleCreate: () => void
}

const LandingPageThumbnailStyled = styled(LandingPageThumbnail)`
  margin: 0.5rem 1rem;
`

const CreateNewThumbnail = styled.div`
  margin: 0.5rem 1rem;
`

const LandingPageThumbnailSectionWrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
`

const LandingPageThumbnailSection: FC<PropsType> = ({
  createText,
  createDisabled,
  handleCreate,
  thumbnails = [],
  ...props
}) => (
  <LandingPageThumbnailSectionWrapper data-testid="lpt-section" {...props}>
    {[
      <CreateNewThumbnail key="create">
        <CardWithMenu showTrigger={false}>
          <Button
            padding={0}
            tertiary
            onClick={handleCreate}
            disabled={createDisabled}
          >
            {createText}
          </Button>
        </CardWithMenu>
      </CreateNewThumbnail>,
      ...thumbnails.map(tn => (
        <LandingPageThumbnailStyled key={tn.id} {...tn} />
      )),
    ]}
  </LandingPageThumbnailSectionWrapper>
)

export { LandingPageThumbnailSection }
export type { PropsType as LandingPageThumbnailSectionPropsType }
