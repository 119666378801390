import React from 'react'
import { useTranslation } from 'react-i18next'

import {
  Typography,
  TextInput,
  Grid,
  styled,
  Dropdown,
  Switch,
  GridItem,
} from '@deliveryhero/armor'

import { Language } from '../../../global/types'
import { DenormalizedLandingPage } from '../selectors'
import { convertRulesToCities } from '../utils'

import { LanguageTabs } from './LanguageTabs'
import { GridHeader, RowAlignedGrid } from './shared-components'

const GridItemFirst = styled(GridItem)`
  width: 34%;
`
const GridItemSecond = styled(GridItem)`
  width: 44%;
  text-align: center;
`
const GridItemThird = styled(GridItem)`
  width: 18%;
  text-align: end;
`
const CitiesDropdown = styled(Dropdown)`
  .OptionList-Item {
    @media only screen and (min-width: 600px) {
      width: max-content;
      max-height: none;
      max-width: none;
      white-space: normal;
    }
  }
`

type IProps = {
  landingPage: DenormalizedLandingPage
  setSelectedLanguage: (index: number) => void
  selectedLanguage: number
  availableLanguages: Language[]
  handleVehicleChange: (id: string, property: string, value: string) => void
}

const getSelectedCitiesIndexes = (
  selectedCities: any[],
  cities: string | any[],
) =>
  selectedCities
    .map((selectedCity: any) => cities.indexOf(selectedCity))
    .filter((c: any) => c !== undefined)

export const VehiclesTab: React.FC<IProps> = ({
  landingPage,
  setSelectedLanguage,
  selectedLanguage,
  availableLanguages,
  handleVehicleChange,
}) => {
  const [t] = useTranslation()
  const landingPageContentsIndex = landingPage.landingPageContents.findIndex(
    lpc => lpc.language.id === availableLanguages[selectedLanguage].id,
  )

  const vehicles =
    landingPage.landingPageContents[landingPageContentsIndex]?.vehicles
  const cities = convertRulesToCities(
    landingPage.landingPageContents[landingPageContentsIndex]?.workflow?.rules,
  )

  return (
    <Grid>
      <Typography paragraph medium>
        <span>
          {t('Settings.Vehicles.SelectTheVehicles')}
          <br />
          {t('Settings.Vehicles.ThoseVehiclesEnabledFor')}
          <br />
          {t('Settings.Vehicles.YouCanAlsoChange')}
          <br />
          <br /> {t('Settings.Vehicles.SwitchBetweenLanguages')}
          <br />
          <br />
        </span>
      </Typography>

      <LanguageTabs
        languages={availableLanguages}
        selectedLanguage={selectedLanguage}
        onSelectLanguage={setSelectedLanguage}
        render={() => (
          <>
            <GridHeader>
              <RowAlignedGrid>
                <GridItemFirst>
                  {t('Settings.Vehicles.Description')}
                </GridItemFirst>
                <GridItemSecond>
                  {t('Settings.Vehicles.CitySelection')}
                </GridItemSecond>
                <GridItemThird>
                  {t('Settings.Vehicles.EnabledVehicles')}
                </GridItemThird>
              </RowAlignedGrid>
            </GridHeader>

            {vehicles.map(v => (
              <RowAlignedGrid style={{ marginTop: 8 }}>
                <GridItem xs={3}>
                  <TextInput
                    value={`${v.text}`}
                    label={t('Settings.Vehicles.Name')}
                    style={{ marginRight: 7 }}
                    onChange={e =>
                      handleVehicleChange(v.id, 'text', e.target.value)
                    }
                  />
                </GridItem>
                <GridItem xs={3}>
                  <TextInput
                    value={`${v.name}`}
                    label={t('Settings.Vehicles.Value')}
                    style={{ marginRight: 7 }}
                    onChange={e =>
                      handleVehicleChange(v.id, 'name', e.target.value)
                    }
                  />
                </GridItem>
                <GridItem xs={3}>
                  <CitiesDropdown
                    enableSelectAllOption
                    multiple
                    options={cities}
                    defaultValue={getSelectedCitiesIndexes(v.cities, cities)}
                    openTagsCount={2}
                    marginRight={20}
                    overflowX="hidden"
                    label="Available for"
                    onChange={(e: { target: { value: any } }) => {
                      const targetValue = e.target.value
                      const selectedCities = targetValue.map(
                        (selectedValue: number) => cities[selectedValue],
                      )
                      handleVehicleChange(v.id, 'cities', selectedCities)
                    }}
                  />
                </GridItem>
                <GridItem
                  xs={3}
                  style={{
                    marginTop: 10,
                    display: 'block',
                    textAlign: 'end',
                    position: 'relative',
                    left: '16px',
                  }}
                >
                  <Switch
                    checked={v.enabled}
                    onChange={e =>
                      handleVehicleChange(v.id, 'enabled', e.target.checked)
                    }
                  />
                </GridItem>
              </RowAlignedGrid>
            ))}
          </>
        )}
      />
    </Grid>
  )
}
