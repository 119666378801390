import { withPrefix } from 'gatsby'
import i18n from 'i18next'
import LanguageDetector from 'i18next-browser-languagedetector'
import Backend from 'i18next-http-backend'
import { initReactI18next } from 'react-i18next'

const i18nBackoffice = () => {
  i18n
    .use(initReactI18next)
    .use(Backend)
    .use(LanguageDetector)
    .init({
      detection: {
        lookupQuerystring: 'lang',
      },
      load: 'languageOnly',
      backend: {
        loadPath: lng => withPrefix(`/locales/${lng}.json`),
      },
      fallbackLng: 'en',
      interpolation: {
        escapeValue: false,
        formatSeparator: ',',
      },
      react: {
        useSuspense: false,
      },
    })
  return i18n
}

// Include the translations in the frontoffice
const i18nFrontoffice = (resources, lng) => {
  i18n.use(initReactI18next).init({
    resources,
    lng,
    debug: true,
    interpolation: {
      escapeValue: false,
      formatSeparator: ',',
    },
  })

  return i18n
}

export { i18nFrontoffice, i18nBackoffice }
