import { Popover } from '@material-ui/core'
import React from 'react'
import { useTranslation } from 'react-i18next'

import {
  Button,
  Typography,
  TextInput,
  Stack,
  StackItem,
  Flex,
  Menu,
  MenuElement,
  Divider,
} from '@deliveryhero/armor'
import {
  AlignLeftIcon,
  AlignCenterIcon,
  AlignRightIcon,
  ListBulletIcon,
  ListNumberIcon,
  ImageUploadIcon,
  LinkIcon,
} from '@deliveryhero/armor-icons'

import { VideoDialog } from '../../../components/Molecules/VideoDialog'
import { toastError } from '../../../components/toasts/Error'
import { Block, BLOCK_TYPES, Image } from '../../../utils/renderer'
import { isGodMode } from '../utils'

const UPLOAD_SIZE_LIMIT_IN_BYTES = 1 * 1024 * 1024

export const PopoverEditor: React.FC<{
  block: Block
  isOpen: boolean
  anchorEl: Element
  onBlocksChange: (blocks: Block[]) => void
  updatePendingUploadImages: (image: Image) => void
  onClose: () => void
}> = ({
  block,
  isOpen,
  anchorEl,
  onClose,
  onBlocksChange,
  updatePendingUploadImages,
}) => {
  const [t] = useTranslation()
  const [videoDialogOpen, setVideoDialogOpen] = React.useState(false)
  const [openVideoDialog, closeVideoDialog] = [
    () => setVideoDialogOpen(true),
    () => setVideoDialogOpen(false),
  ]

  if (!block) return null

  const onChangeValue = (e: React.ChangeEvent<HTMLInputElement>) => {
    onBlocksChange([
      {
        ...block,
        [e.target.name]: e.target.value,
      },
    ])
  }

  const onChangeStyleProperty = (e: React.ChangeEvent<HTMLInputElement>) => {
    onBlocksChange([
      {
        ...block,
        properties: {
          ...(block.properties || {}),
          style: {
            ...(block.properties?.style || {}),
            [e.target.name]: e.target.value,
          },
        },
      },
    ])
  }

  const handleImageUpload = (e: { target: { files: File[] } }) => {
    const file = e.target.files[0]
    if (file) {
      if (file.size > UPLOAD_SIZE_LIMIT_IN_BYTES) {
        toastError(t('LandingPage.UploadLimitExceedError'))
        return
      }

      const uploadedImage = Object.assign(file, {
        preview: URL.createObjectURL(file),
      })
      updatePendingUploadImages({
        ...block,
        value: uploadedImage.preview,
        file,
      })
      onBlocksChange([
        {
          ...block,
          value: uploadedImage.preview,
          type: BLOCK_TYPES.IMAGE_BLOCK,
        },
      ])
      onClose()
    }
  }

  const onChangeOrientation = (textAlign: string) => {
    onBlocksChange([
      {
        ...block,
        properties: {
          ...block.properties,
          textAlign,
        },
      },
    ])
  }

  const onChangeTextType = (type: BLOCK_TYPES, listType: string) => {
    // TODO: Right now we're closing bc when we change the type of the element the popover jump to the top of the screen, fix this
    onClose()
    onBlocksChange([
      {
        ...block,
        type,
        properties: {
          ...block.properties,
          type: listType,
        },
      },
    ])
  }

  const saveVideoURL = videoURL => {
    onClose()
    onBlocksChange([
      {
        ...block,
        value: videoURL,
        type: BLOCK_TYPES.VIDEO_BLOCK,
      },
    ])
  }

  return (
    <>
      <Popover
        open={isOpen && !videoDialogOpen}
        anchorEl={anchorEl}
        onClose={onClose}
      >
        <Stack>
          <StackItem>
            {block.type !== BLOCK_TYPES.IMAGE_BLOCK &&
              block.type !== BLOCK_TYPES.VIDEO_BLOCK && (
                <Flex>
                  <Button
                    tertiary
                    onClick={() => onChangeOrientation('left')}
                    title="Left"
                  >
                    <AlignLeftIcon />
                  </Button>
                  <Button
                    tertiary
                    onClick={() => onChangeOrientation('center')}
                    title="Center"
                  >
                    <AlignCenterIcon />
                  </Button>
                  <Button
                    tertiary
                    onClick={() => onChangeOrientation('right')}
                    title="Right"
                  >
                    <AlignRightIcon />
                  </Button>
                  <Button tertiary disabled>
                    <Typography>|</Typography>
                  </Button>
                  <Button
                    tertiary
                    onClick={() =>
                      onChangeTextType(BLOCK_TYPES.TEXT_BLOCK, 'none')
                    }
                    title="Normal text"
                  >
                    <AlignCenterIcon />
                  </Button>
                  <Button
                    tertiary
                    onClick={() =>
                      onChangeTextType(BLOCK_TYPES.TEXT_LIST, 'bullet')
                    }
                    title="Bullet list"
                  >
                    <ListBulletIcon />
                  </Button>
                  <Button
                    tertiary
                    onClick={() =>
                      onChangeTextType(BLOCK_TYPES.TEXT_LIST, 'numbered')
                    }
                    title="Numbered list"
                  >
                    <ListNumberIcon />
                  </Button>
                </Flex>
              )}
            {isGodMode() && block.type === BLOCK_TYPES.TEXT_BLOCK && (
              <TextInput
                wide
                name="fontSize"
                label={`${t('LandingPage.FontSize')} in rem or px`}
                value={block.properties?.style?.fontSize}
                onChange={onChangeStyleProperty}
              />
            )}
          </StackItem>
          <StackItem>
            {block.type === BLOCK_TYPES.IMAGE_BLOCK ||
            block.type === BLOCK_TYPES.VIDEO_BLOCK ? (
              <Menu>
                <MenuElement tag="label">
                  <ImageUploadIcon large color="#1c5ece" marginRight="0.5em" />
                  <input
                    type="file"
                    accept="image/*"
                    onChange={handleImageUpload}
                    style={{ display: 'none' }}
                  />
                  <Typography style={{ fontWeight: 'normal' }}>
                    {t('LandingPage.UploadImage')}
                  </Typography>
                </MenuElement>
                <Divider marginLeft="1em" marginRight="1em" />
                <MenuElement onClick={openVideoDialog}>
                  <LinkIcon large color="#1c5ece" marginRight="0.5em" />
                  <Typography style={{ fontWeight: 'normal' }}>
                    {t('LandingPage.AddVideoLink')}
                  </Typography>
                </MenuElement>
              </Menu>
            ) : (
              <TextInput
                wide
                multiline={block.properties?.type !== 'none'}
                name="value"
                value={block.value}
                onChange={onChangeValue}
              />
            )}
          </StackItem>
        </Stack>
      </Popover>
      {videoDialogOpen && (
        <VideoDialog
          onCancel={() => {
            onClose()
            closeVideoDialog()
          }}
          onSave={saveVideoURL}
          initalVideoURL={
            block.type === BLOCK_TYPES.VIDEO_BLOCK ? block.value : ''
          }
        />
      )}
    </>
  )
}
