import { withPrefix } from 'gatsby'
import React, { FC } from 'react'

import { styled, Button } from '@deliveryhero/armor'

import {
  CardWithMenu,
  CardWithMenuPropsType,
  TitleWithSubtitle,
  TitleWithSubtitlePropsType,
} from '../../Atoms'

type PropsType = {
  id: string
  handlePublish?: () => void
  publishText?: string
} & CardWithMenuPropsType &
  TitleWithSubtitlePropsType

const CardWithMenuStyled = styled(CardWithMenu)`
  grid-area: card;
`

const TitleWithSubtitleStyled = styled(TitleWithSubtitle)`
  grid-area: text;
`

const ButtonStyled = styled(Button)`
  grid-area: button;
`

const TitleLink = styled.a`
  color: inherit;
  text-decoration: none;
  &:hover {
    text-decoration: underline;
  }
`

const LandingPageThumbnailWrapper = styled.div`
  display: inline-grid;
  gap: 16px;
  grid-template:
    [row1-start] 'card card' 216px [row1-end]
    [row2-start] 'text button' 40px [row2-end]
    / 216px 80px;
  ${ButtonStyled} {
    display: none;
  }
  &:hover ${ButtonStyled} {
    display: inline-flex;
  }
`

const LandingPageThumbnail: FC<PropsType> = ({
  id,
  handlePublish,
  publishText,
  image,
  backgroundColor,
  menuColor,
  menuElements,
  title,
  subtitle,
  ...props
}) => (
  <LandingPageThumbnailWrapper data-testid="lp-thumbnail" {...props}>
    <CardWithMenuStyled
      image={image}
      backgroundColor={backgroundColor}
      menuColor={menuColor}
      menuElements={menuElements}
    />
    <TitleWithSubtitleStyled subtitle={subtitle}>
      <TitleLink href={withPrefix(`/app/editor/${id}`)}>{title}</TitleLink>
    </TitleWithSubtitleStyled>
    {publishText && (
      <ButtonStyled onClick={handlePublish}>{publishText}</ButtonStyled>
    )}
  </LandingPageThumbnailWrapper>
)

export { LandingPageThumbnail }
export type { PropsType as LandingPageThumbnailPropsType }
