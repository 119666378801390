import { withPrefix } from 'gatsby'
import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'

import {
  TextInput,
  Typography,
  styled,
  Button,
  Dialog,
  DialogContent,
  DialogTitle,
  Grid,
  Card,
  Stack,
  StackItem,
  Flex,
  FlexItem,
} from '@deliveryhero/armor'

import { Language, KeyedResponseData, SEO } from '../../../global/types'
import { DenormalizedLandingPage } from '../selectors'

import { LanguageTabs } from './LanguageTabs'
import { TagManager } from './TagManager'
import {
  Title,
  Description,
  DescriptionTitle,
  DescriptionBody,
} from './shared-components'

const LandingPagePathTypography = styled(Typography)`
  font-size: 12px;
`

const LandingPageTitleTypography = styled(Typography)`
  margin-bottom: 6px;
  color: #0b53bf;
`

const PreviewImage = styled.img`
  width: 112px;
  height: 112px;
  border-radius: 12px;
`

const FlexPadded = styled(Flex)`
  padding: 16px;
`

export const SEOTab: React.FC<{
  landingPage: DenormalizedLandingPage
  seo: SEO
  setSelectedLanguage: (index: number) => void
  selectedLanguage: number
  availableLanguages: Language[]
  handleChanges: (newChanges: KeyedResponseData) => void
}> = ({
  landingPage,
  seo,
  availableLanguages,
  selectedLanguage,
  setSelectedLanguage,
  handleChanges,
}) => {
  const [isTagModalOpen, setIsTagModalOpen] = useState(false)
  const [t] = useTranslation()
  const onSeoPropChange = (e: React.ChangeEvent<HTMLInputElement>) =>
    handleChanges({
      seos: {
        [seo.id]: {
          [e.target.name]: e.target.value,
          restOperation: 'PATCH',
        },
      },
    })

  const handleTagChange = (tags: string[] = []) => {
    handleChanges({
      seos: {
        [seo.id]: {
          tags,
          restOperation: 'PATCH',
        },
      },
    })
  }

  const { description } = landingPage.landingPageContents[selectedLanguage].seo
  const truncatedDescription =
    description.length > 150 ? `${description.substring(150)}...` : description
  const { theme } = landingPage
  return (
    <Grid>
      <Title subSectionTitle>{t('Settings.Seo.SearchPreview')}</Title>
      <DescriptionBody paragraph small>
        {t('Settings.Seo.SeoPreviewHelper')}
      </DescriptionBody>

      <LanguageTabs
        languages={availableLanguages}
        selectedLanguage={selectedLanguage}
        onSelectLanguage={setSelectedLanguage}
        render={() => (
          <Card>
            <FlexPadded>
              <FlexItem>
                <Stack>
                  <StackItem>
                    <LandingPagePathTypography small>
                      {landingPage.baseUrl}
                    </LandingPagePathTypography>{' '}
                  </StackItem>

                  <StackItem>
                    <LandingPageTitleTypography subSectionTitle>
                      {seo.title}
                    </LandingPageTitleTypography>
                  </StackItem>

                  <StackItem>
                    <Typography paragraph>{truncatedDescription}</Typography>
                  </StackItem>
                </Stack>
              </FlexItem>
              <FlexItem>
                <PreviewImage
                  src={withPrefix(theme.logoIcon)}
                  alt={theme.name}
                />
              </FlexItem>
            </FlexPadded>
          </Card>
        )}
      />

      <Description>
        <DescriptionTitle>{t('Settings.Seo.SeoTitle')}</DescriptionTitle>
        <DescriptionBody paragraph small>
          {t('Settings.Seo.SeoTitleHelper')}
        </DescriptionBody>

        <TextInput
          name="title"
          label="SEO Title"
          value={seo.title}
          onChange={onSeoPropChange}
          style={{ width: '50%' }}
        />

        <DescriptionTitle>{t('Settings.Seo.SeoTags')}</DescriptionTitle>
        <DescriptionBody paragraph small>
          {t('Settings.Seo.SeoTagsDescription')}
        </DescriptionBody>

        <Dialog
          maxWidth="xs"
          open={isTagModalOpen}
          onClose={() => setIsTagModalOpen(false)}
        >
          <DialogTitle>{t('Settings.Seo.ManageSeoTags')}</DialogTitle>
          <DialogContent>
            <TagManager tags={seo.tags} onTagChange={handleTagChange} />
          </DialogContent>
        </Dialog>

        <Stack>
          <StackItem>
            <TagManager
              readonly
              tags={seo.tags}
              onTagChange={handleTagChange}
            />
          </StackItem>
          <StackItem>
            <Button
              secondary
              marginTop={1}
              onClick={() => setIsTagModalOpen(true)}
            >
              Manage tags
            </Button>
          </StackItem>
        </Stack>

        <DescriptionTitle>{t('Settings.Seo.SeoDescription')}</DescriptionTitle>
        <DescriptionBody paragraph small>
          {t('Settings.Seo.SeoDescriptionHelper')}
        </DescriptionBody>
        <TextInput
          name="description"
          label="Type or paste here"
          value={seo.description}
          multiline
          rows={5}
          wide
          onChange={onSeoPropChange}
        />
      </Description>
    </Grid>
  )
}
