import { withPrefix } from 'gatsby'
import React from 'react'

import {
  SideSheet,
  SideSheetHeader,
  SideSheetBody,
  styled,
} from '@deliveryhero/armor'

import { Theme } from '../../../global/types'

const ThemesContainer = styled.div`
  display: flex;
  justify-content: flex-start;
  flex-wrap: wrap;
`

const ThemeComponent: React.FC<{
  theme: Theme
  selected: boolean
  onSelect: (themeId: string) => void
}> = ({ theme, selected, onSelect }) => {
  const ThemeContainer = styled.div`
    padding: 8px;
    border: 4px solid ${selected ? '#0B53BF' : '#F7F7F7'};
    background-color: ${selected ? 'unset' : '#F7F7F7'};
    margin-right: 12px;
    border-radius: 6px;
    display: flex;
  `
  const ThemeItem = styled.div`
    background: ${theme.secondaryColor} url('${withPrefix(theme.logo)}')
      no-repeat center;
    background-size: contain;
    display: inline-block;
    cursor: pointer;
    border-radius: 6px;
    width: 150px;
    height: 80px;
    justify-content: center;
    align-items: center;
  `

  return (
    <ThemeContainer onClick={() => onSelect(theme.id)}>
      <ThemeItem />
    </ThemeContainer>
  )
}

export const ThemesSidebar: React.FC<{
  isOpen: boolean
  onClose: () => void
  themes: {
    [id: string]: Theme
  }
  selectedThemeId: string
  onSelectedTheme: (themeId: string) => void
}> = ({ isOpen, onClose, themes, selectedThemeId, onSelectedTheme }) => (
  <SideSheet open={isOpen} onClose={onClose}>
    <SideSheetHeader title="Themes" />
    <SideSheetBody>
      <ThemesContainer>
        {Object.keys(themes).map(themeId => (
          <ThemeComponent
            theme={themes[themeId]}
            selected={selectedThemeId === themeId}
            onSelect={onSelectedTheme}
          />
        ))}
      </ThemesContainer>
    </SideSheetBody>
  </SideSheet>
)
