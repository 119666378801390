import { without } from 'lodash'

import { KeyedResponseData } from '../../../../global/types'

import { LocationRule } from './LocationsInput'

export const stringToRules = (
  s: string | undefined,
): Map<string, LocationRule> => {
  if (!s?.replace(/\s/g, '')) {
    return new Map()
  }

  const splitRules = s
    .trim()
    // removes new line characters
    .replace(/[\r\n]/gm, '')
    // this character represents the end of a location in the string
    .split(';')
    .filter(it => it)
    // this separates the components of a location such as name$locationId$workflow or for newer ones name$locationId
    .map(it => it.split('$'))

  return splitRules.reduce((map, [name, locationId, workflow]) => {
    map.set(locationId, {
      name,
      locationId: locationId.replace(';', ''),
      workflow: workflow?.replace(';', ''),
      selected: true,
    })
    return map
  }, new Map())
}

export const rulesToString = (r: LocationRule[]) =>
  r
    .map(
      it =>
        `${it.name}$${it.locationId}${it.workflow ? `$${it.workflow}` : ''};`,
    )
    .join('\n')

export const hasDuplicateRuleNames = (changes: KeyedResponseData): boolean => {
  let seen = new Set()
  return Object.keys(changes.workflows).some(it => {
    const rules = stringToRules(changes.workflows[it].rules)
    const hasDuplicates = Array.from(rules.values()).some(
      current => seen.size === seen.add(current.name).size,
    )
    seen = new Set()
    return hasDuplicates
  })
}

export const getVehicleLocationUpdates = (
  vehicles: Record<string, any>[],
  oldLocationNames: string[],
  newLocationNames: string[],
) => {
  const oldSet = new Set(oldLocationNames)
  const newSet = new Set(newLocationNames)

  // This logic is based on the fact that only one item is changed at a time
  // Eg: say you want to find the what changed between [1,2,3,4,5] and [1,2,7,4,5]
  // '3' is not in the second list, so it has changed
  // '7' is not in the first list, so it is what '3' changed to
  const oldCityName = oldLocationNames.find(loc => !newSet.has(loc))
  const newCityName = newLocationNames.find(loc => !oldSet.has(loc))

  const updates = {}

  vehicles.forEach(vehicle => {
    if (vehicle.cities.includes(oldCityName))
      updates[vehicle.id] = {
        cities: without(vehicle.cities, oldCityName).concat([newCityName]),
        restOperation: 'PATCH',
      }
  })

  return updates
}

/**
 *
 * @param arr - Original array
 * @param src - Index of source element
 * @param dst - Index of destination element
 * @returns - A new array with elements in position src moved to position dst
 */
export function moveElement(arr: any[], src, dst) {
  const copy = arr.slice()
  const removed = copy.splice(src, 1)

  return copy.slice(0, dst).concat(removed).concat(copy.slice(dst))
}
