import { Router } from '@gatsbyjs/reach-router'
import { withPrefix } from 'gatsby'
import React from 'react'
import { I18nextProvider } from 'react-i18next'

import { Layout } from '../components/layout'
import { i18nBackoffice } from '../config/i18n'
import { ErrorBoundary } from '../containers/ErrorBoundary'
import Home from '../containers/home'
import LandingPageEditor from '../containers/landing-page'
import Reports from '../containers/reports'
import { Themes } from '../containers/themes'
import { GlobalProvider } from '../global/provider'
import { ThemeComponent } from '../theme'

const NotFound = ({ location }) => (
  <p>Sorry, nothing here {JSON.stringify(location)}</p>
)

export default () => (
  <ErrorBoundary>
    <I18nextProvider i18n={i18nBackoffice()}>
      <GlobalProvider>
        <Layout>
          <Router basepath={withPrefix('/app')}>
            <Reports path="/reports" />
            <Home path="/" />
            <LandingPageEditor path="/editor/:landingPageId" />
            <Themes path="/themes" />
            <ThemeComponent path="/themes/:themeId" />
            <NotFound default />
          </Router>
        </Layout>
      </GlobalProvider>
    </I18nextProvider>
  </ErrorBoundary>
)
