import {
  styled,
  Tabs as ArmorTabs,
  TabView as ArmorTabView,
} from '@deliveryhero/armor'

const TabView = styled(ArmorTabView)`
  background-color: unset;
`

const Tabs = styled(ArmorTabs)`
  box-shadow: unset;
`

export { TabView, Tabs }
