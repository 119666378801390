import { navigate } from '@gatsbyjs/reach-router'
import { withPrefix } from 'gatsby'
import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'

import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  styled,
  TextInput,
  Typography,
  Grid,
} from '@deliveryhero/armor'

import { LoadingSpinner } from '../../components/Atoms/LoadingSpinner'
import { toastError } from '../../components/toasts/Error'

import { createLandingPage } from './api'

const DialogHeader = styled(Typography)`
  margin-bottom: 0.5rem;
`

const DialogDescription = styled(Typography)`
  color: #717171;
`

type CreateLandingPageDialogProps = {
  isModalOpen: boolean
  closeModal: () => void
  selectedCountryCode: string | undefined
}

export const CreateLandingPageDialog = ({
  isModalOpen,
  closeModal,
  selectedCountryCode,
}: CreateLandingPageDialogProps) => {
  const { t } = useTranslation()

  const [landingPageName, setLandingPageName] = useState('')

  const [isCreating, setIsCreating] = useState(false)

  const onCreateLandingPage = async () => {
    if (!landingPageName || !selectedCountryCode) {
      return
    }

    setIsCreating(true)

    try {
      const { id } = await createLandingPage(
        selectedCountryCode,
        landingPageName,
        '/',
      )
      navigate(withPrefix(`/app/editor/${id}`))
      setLandingPageName('')
    } catch (e) {
      toastError(e.message)
    } finally {
      setIsCreating(false)
    }
  }

  return (
    <Dialog
      open={isModalOpen}
      onClose={closeModal}
      disableCloseButton={isCreating}
    >
      <DialogTitle>
        <DialogHeader subSectionTitle>
          {t('Home.CreateNewLandingPage')}
        </DialogHeader>
        <DialogDescription paragraph>
          {t('Home.PleaseNameYourProject')}
        </DialogDescription>
      </DialogTitle>

      <>
        <DialogContent>
          {isCreating ? (
            <LoadingSpinner width="50px" />
          ) : (
            <TextInput
              label="Project name"
              name="name"
              value={landingPageName}
              autoFocus
              onChange={e => setLandingPageName(e.target.value)}
              wide
            />
          )}
        </DialogContent>
        {!isCreating && (
          <DialogActions>
            <Button tertiary onClick={closeModal}>
              {t('Home.Cancel')}
            </Button>
            <Button onClick={onCreateLandingPage}>Create</Button>
          </DialogActions>
        )}
      </>
    </Dialog>
  )
}
