import { isEmpty } from 'lodash'
import React, { useCallback, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'

import {
  ContentSwitcher,
  ContentSwitcherControl,
  Dropdown,
  Grid,
  Pack,
  PackItem,
  Typography,
} from '@deliveryhero/armor'

import { Language, KeyedResponseData } from '../../../global/types'
import { DenormalizedLandingPage } from '../selectors'

import { LocationRule, LocationsInput } from './LocationsInput/LocationsInput'
import {
  rulesToString,
  stringToRules,
  getVehicleLocationUpdates,
} from './LocationsInput/utils'

type IProps = {
  landingPage: DenormalizedLandingPage
  setSelectedLanguage: (index: number) => void
  selectedLanguage: number
  availableLanguages: Language[]
  handleChanges: (newChanges: KeyedResponseData) => void
}

export const LocationsTab: React.FC<IProps> = ({
  landingPage,
  availableLanguages,
  setSelectedLanguage,
  selectedLanguage,
  handleChanges,
}) => {
  const [t] = useTranslation()
  const { workflow, vehicles } =
    landingPage.landingPageContents[selectedLanguage]
  const [filterSelected, setSelectedFilter] = useState(false)

  const locationNames = useMemo(
    () => Array.from(stringToRules(workflow.rules).values()).map(r => r.name),
    [workflow.rules],
  )

  const onLocationChange = useCallback(
    (rules: LocationRule[]) => {
      const vechicleUpdates = getVehicleLocationUpdates(
        vehicles,
        locationNames,
        rules.map(rule => rule.name),
      )
      const workflowUpdates = {
        [workflow.id]: {
          rules: rulesToString(rules),
          restOperation: 'PATCH',
        },
      }
      if (isEmpty(vechicleUpdates))
        handleChanges({
          workflows: workflowUpdates,
        })
      else
        handleChanges({
          vehicles: vechicleUpdates,
          workflows: workflowUpdates,
        })
    },
    [locationNames, workflow.id, vehicles, handleChanges],
  )

  const filterOptions = [
    { label: 'All', value: 'All' },
    { label: 'Selected', value: 'Selected' },
  ]

  return (
    <Grid style={{ flexDirection: 'column' }}>
      <Typography paragraph medium marginBottom="36px">
        {t('Settings.Workflow.Description')}
      </Typography>

      <Pack justifyContent="space-between">
        <PackItem>
          <ContentSwitcher
            defaultSwitchName="0"
            onSwitchNameChange={(switchName: string) => {
              setSelectedLanguage(parseInt(switchName, 10))
            }}
          >
            {availableLanguages.map((it, index) => (
              <ContentSwitcherControl key={it.name} switchName={`${index}`}>
                {it.name}
              </ContentSwitcherControl>
            ))}
          </ContentSwitcher>
        </PackItem>
        <PackItem>
          <Dropdown
            defaultValue="All"
            label="Filter"
            data-testid="filter"
            options={filterOptions}
            onChange={() => {
              setSelectedFilter(!filterSelected)
            }}
          />
        </PackItem>
      </Pack>

      <LocationsInput
        onRuleChange={onLocationChange}
        countryId={landingPage.country.id}
        rules={workflow?.rules}
        filterSelected={filterSelected}
      />
    </Grid>
  )
}
