/* eslint-disable camelcase */

import { backofficeOnlyConfig } from '../../config/global'
import { getUseAPI } from '../../utils/api'

const { useAPI } = getUseAPI(backofficeOnlyConfig.dodoApiBaseUrl)

type LocationResourceObject = {
  relationships?: unknown
  type: 'locations'
  id: string
  attributes: {
    name: string | null
    address: string
    batch_number: number | null
    calendly_link: string | null
    rooster_contract_id: number | null
    starting_points: number[] | null
  }
}
type LocationsCollectionSchema = {
  data?: LocationResourceObject[]
  links: unknown
  meta: {
    total_count: number
  }
}

export type AraraLocation = {
  id: string
  name: string | null
}
export const useGetLocations = (
  country: string,
): {
  locations: AraraLocation[]
  loading: boolean
} => {
  const {
    data: response,
    error,
    loading,
  } = useAPI<LocationsCollectionSchema | undefined>(
    `/arara/locations?country=${country}`,
  )

  if (error) {
    console.error(error)
  }
  return {
    locations:
      response?.data?.map(({ id, attributes: { name } }) => ({
        id,
        name,
      })) ?? [],
    loading,
  }
}
