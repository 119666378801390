import { sortBy } from 'lodash'
import React, { useEffect, useState } from 'react'
import { DragDropContext, Draggable, Droppable } from 'react-beautiful-dnd'
import { useTranslation } from 'react-i18next'
import { useDebouncedCallback } from 'use-debounce'

import {
  TextInput,
  Search,
  Tooltip,
  IconButton,
  FormField,
  Typography,
  Grid,
  ContextMenu,
  styled,
} from '@deliveryhero/armor'
import {
  InfoOutlineIcon,
  InfoIcon,
  DragIcon,
  EllipsisVerticalIcon,
} from '@deliveryhero/armor-icons'

import { AraraLocation } from '../../arara.api'

import { LocationRule } from './LocationsInput'
import { moveElement } from './utils'

export type IProps = {
  araraLocations: AraraLocation[]
  locations: Map<string, LocationRule>
  handleSelectedRuleChange: (
    checked: boolean,
    location: LocationRule,
    order: string[],
  ) => void
  handleOrderChange: (order: string[]) => void
  filterSelected: boolean
}

const mergeLocations = (
  araraLocations: AraraLocation[],
  locations: Map<string, LocationRule>,
) =>
  araraLocations.reduce((map, { name, id }) => {
    const loc = locations.get(id)
    map.set(id, {
      name: loc ? loc.name : name,
      locationId: id,
      selected: loc ? loc.selected : false,
      workflow: undefined,
    })

    return map
  }, locations)

type RowProps = {
  viewLocations: Map<string, LocationRule>
  location: LocationRule
  onChange: (e: any) => void
  onSetSelected: (selected: boolean) => void
  index: number
  moveToStart: () => void
  moveToEnd: () => void
}

const BorderLessTableCell = styled.td`
  border: none;
  overflow: hidden;
  text-overflow: ellipsis;
`

const LocationsRow: React.FC<RowProps> = ({
  viewLocations,
  location,
  onChange,
  onSetSelected,
  index,
  moveToStart,
  moveToEnd,
}) => {
  const [buffer, setBuffer] = useState(location.name)
  const [error, setError] = useState(false)
  const [menuOpen, setMenuOpen] = useState(false)

  const [t] = useTranslation()

  useEffect(() => {
    setBuffer(location.name)
  }, [location])
  const debouncedChange = useDebouncedCallback(() => {
    onChange(buffer)
  }, 350)

  return (
    <Draggable
      key={location.locationId}
      draggableId={location.locationId}
      index={index}
    >
      {(provided, { isDragging }) => (
        <tr
          {...provided.draggableProps}
          ref={provided.innerRef}
          style={{
            ...provided.draggableProps.style,
            background: 'white',
            boxShadow: isDragging ? '0px 2px 6px rgba(0, 0, 0, 0.1)' : '',
          }}
        >
          <BorderLessTableCell {...provided.dragHandleProps}>
            <DragIcon />
          </BorderLessTableCell>
          <BorderLessTableCell style={{ maxWidth: '180px' }}>
            {location.locationId}
          </BorderLessTableCell>
          <BorderLessTableCell>
            <FormField autoMargin>
              <TextInput
                width="100%"
                disabled={!location.selected}
                value={buffer}
                after={
                  !location.selected ? (
                    <Tooltip
                      enablePortal
                      zIndex={3999}
                      align="bottom-end"
                      content={t('Settings.Locations.TooltipEditDisabled')}
                    >
                      <IconButton>
                        <InfoOutlineIcon small />
                      </IconButton>
                    </Tooltip>
                  ) : (
                    error && (
                      <Tooltip
                        enablePortal
                        zIndex={3999}
                        align="bottom-end"
                        content={t('Settings.Locations.TooltipEditDuplicate')}
                      >
                        <IconButton>
                          <InfoOutlineIcon small color="#D73D45" />
                        </IconButton>
                      </Tooltip>
                    )
                  )
                }
                error={error}
                onChange={e => {
                  const hasDuplicates = Array.from(viewLocations.values()).some(
                    it => it.name === e.target.value,
                  )
                  // this characters break the underlying string that we use to store the locations
                  // we have to avoid them for now.
                  const forbiddenCharaters = /[$;]/
                  if (forbiddenCharaters.test(e.target.value)) {
                    return
                  }
                  setBuffer(e.target.value)
                  setError(hasDuplicates)
                  debouncedChange()
                }}
              />
            </FormField>
          </BorderLessTableCell>
          <BorderLessTableCell>
            <ContextMenu
              trigger={
                <IconButton>
                  <EllipsisVerticalIcon large color="#3F53B9" />
                </IconButton>
              }
              open={menuOpen}
              onOpenChange={op => setMenuOpen(op)}
              menuElements={[
                {
                  id: 'toTop',
                  label: t('Settings.Locations.BringToTop'),
                  props: {
                    disabled: index === 0,
                    onClick: () => {
                      moveToStart()
                      setMenuOpen(false)
                    },
                  },
                },
                {
                  id: 'toBottom',
                  label: t('Settings.Locations.BringToBottom'),
                  props: {
                    disabled: index === viewLocations.size - 1,
                    onClick: () => {
                      moveToEnd()
                      setMenuOpen(false)
                    },
                  },
                },
                {
                  id: 'toggleSelected',
                  label: location.selected
                    ? t('Settings.Locations.DisableField')
                    : t('Settings.Locations.EnableField'),
                  props: {
                    onClick: () => {
                      onSetSelected(!location.selected)
                      setMenuOpen(false)
                    },
                  },
                },
              ]}
            />
          </BorderLessTableCell>
        </tr>
      )}
    </Draggable>
  )
}

function getInitialOrder(viewLocs: LocationRule[]) {
  const enabledLocs = viewLocs.filter(loc => loc.selected)
  const disabledLocs = viewLocs.filter(loc => !loc.selected)
  return enabledLocs
    .map(loc => loc.locationId)
    .concat(disabledLocs.map(loc => loc.locationId))
}

export const LocationsTable = ({
  araraLocations,
  locations,
  handleSelectedRuleChange,
  handleOrderChange,
  filterSelected,
}: IProps) => {
  const [searchFilter, setSearchFilter] = useState('')
  const [t] = useTranslation()

  const debouncedSearch = useDebouncedCallback(
    // function
    (value: string) => {
      setSearchFilter(value)
    },
    // delay in ms
    350,
  )

  const [viewLocations, setViewLocations] = useState(
    mergeLocations(araraLocations, locations),
  )
  const [order, setOrder] = useState(
    getInitialOrder(Array.from(viewLocations.values())),
  )

  useEffect(() => {
    setViewLocations(mergeLocations(araraLocations, locations))
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [locations])

  const filteredLocations = sortBy(
    Array.from(viewLocations.values()).filter(it => {
      if (filterSelected && !it.selected) {
        return false
      }
      if (
        searchFilter &&
        !it.name.toLocaleLowerCase().includes(searchFilter.toLocaleLowerCase())
      ) {
        return false
      }
      return true
    }),
    it => order.findIndex(locationId => locationId === it.locationId),
  )

  const handleDragEnd = result => {
    const newOrder = moveElement(
      order,
      result.source.index,
      result.destination.index,
    )
    setOrder(newOrder)
    handleOrderChange(newOrder)
  }

  return (
    <>
      <Search
        data-testid="search"
        marginBottom="16px"
        marginTop="16px"
        enableSuggestions={false}
        onChange={e => {
          debouncedSearch(e.target.value)
        }}
        icon={<InfoIcon small />}
        wide
      />

      {filteredLocations.length ? (
        <DragDropContext onDragEnd={handleDragEnd}>
          <table>
            <thead>
              <tr>
                <th> </th>
                <th style={{ fontSize: '14px', width: '50%' }}>
                  {t('Settings.Locations.Location')}
                </th>
                <th style={{ fontSize: '14px', width: '40%' }}>
                  {t('Settings.Locations.DisplayName')}
                </th>
                <th style={{ fontSize: '14px' }}>
                  {t('Settings.Locations.Actions')}
                </th>
              </tr>
            </thead>
            <Droppable droppableId="rows" direction="vertical">
              {provided => (
                <tbody {...provided.droppableProps} ref={provided.innerRef}>
                  {filteredLocations.map(it => {
                    const index = order.findIndex(
                      locId => locId === it.locationId,
                    )
                    return (
                      <LocationsRow
                        index={index}
                        viewLocations={viewLocations}
                        key={it.locationId}
                        location={it}
                        moveToStart={() => {
                          const newOrder = moveElement(order, index, 0)
                          setOrder(newOrder)
                          handleOrderChange(newOrder)
                        }}
                        moveToEnd={() => {
                          const newOrder = moveElement(
                            order,
                            index,
                            order.length - 1,
                          )
                          setOrder(newOrder)
                          handleOrderChange(newOrder)
                        }}
                        onChange={e => {
                          handleSelectedRuleChange(
                            it.selected,
                            {
                              ...it,
                              name: e,
                            },
                            order,
                          )
                        }}
                        onSetSelected={selected => {
                          handleSelectedRuleChange(selected, it, order)
                        }}
                      />
                    )
                  })}
                </tbody>
              )}
            </Droppable>
          </table>
        </DragDropContext>
      ) : (
        <Grid style={{ justifyContent: 'space-evenly', marginTop: '18px' }}>
          <Typography paragraph large>
            {t('Settings.Locations.Empty')}
          </Typography>
        </Grid>
      )}
    </>
  )
}
