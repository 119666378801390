import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'

import {
  Button,
  Grid,
  GridItem,
  styled,
  Container,
  Message,
} from '@deliveryhero/armor'
import { CogIcon } from '@deliveryhero/armor-icons'

import { publishLandingPage } from '../api'
import { DenormalizedLandingPage } from '../selectors'

type IProps = {
  landingPage: DenormalizedLandingPage
  changes: Record<string, unknown>
  isPreviewOpen: boolean
  setIsPreviewOpen: (isPreviewOpen: boolean) => void
  isThemeSidebarOpen: boolean
  setIsThemeSidebarOpen: (isThemeSidebarOpen: boolean) => void
  onSaveChanges: () => void
  toggleSidebar: () => void
}

const SecondNav = styled(Container)`
  height: 49px;
  align-items: center;
`

const NavigationButton = styled(Button)`
  font-size: 0.875rem;
`

const SettingsButton = styled(Button)`
  border: 0px;
  font-size: 1.5rem;
  width: 4rem;
  height: 2.5rem;
`

const StyledMessage = styled(Message)`
  text-align: center;
  border: none;
`

const SubNavBar: React.FC<IProps> = ({
  landingPage,
  isPreviewOpen,
  changes,
  isThemeSidebarOpen,
  setIsPreviewOpen,
  onSaveChanges,
  toggleSidebar,
  setIsThemeSidebarOpen,
}) => {
  const [t] = useTranslation()

  const [showPublishMessage, setShowPublishMessage] = useState(false)
  const [isPublishDisabled, setPublishDisabled] = useState(false)

  const onPublishLandingPage = () => {
    if (landingPage.id && landingPage.landingPageDomain) {
      // Take env variables from current deployment
      publishLandingPage(
        landingPage.id,
        landingPage.landingPageDomain,
        window.env,
      )
        .then(() => {
          setPublishDisabled(true)
          setShowPublishMessage(true)
        })
        .catch(err => console.error({ err }))
    }
  }

  return (
    <>
      {showPublishMessage && (
        <StyledMessage
          data-testid="SubNavBar-publish-message"
          disableIcon
          disableCloseButton
          info
        >
          {t('LandingPage.TheProject')} {`"${landingPage.name}"`}{' '}
          {t('LandingPage.IsBeingPublished')} <br />
          {t('LandingPage.ThisProcessUsuallyTakes')}
        </StyledMessage>
      )}
      <SecondNav>
        <Grid>
          <GridItem xs={6} md={9}>
            <NavigationButton
              tertiary
              onClick={() => setIsThemeSidebarOpen(!isThemeSidebarOpen)}
            >
              {t('LandingPage.Themes')}
            </NavigationButton>
            <NavigationButton
              tertiary
              onClick={() => setIsPreviewOpen(!isPreviewOpen)}
            >
              {t('LandingPage.Preview.Preview')}
            </NavigationButton>
          </GridItem>
          <GridItem xs={6} md={3}>
            <SettingsButton
              data-testid="settings-button"
              marginRight={8}
              secondary
              onClick={toggleSidebar}
            >
              <CogIcon />
            </SettingsButton>
            <Button
              primary
              disabled={!changes || Object.keys(changes).length === 0}
              onClick={onSaveChanges}
            >
              {t('LandingPage.Save')}
            </Button>
            {landingPage.id && (
              <Button
                data-testid="SubNavBar-button-publish"
                style={{
                  marginLeft: 8,
                }}
                primary
                onClick={onPublishLandingPage}
                disabled={isPublishDisabled}
              >
                {t('LandingPage.Publish')}
              </Button>
            )}
          </GridItem>
        </Grid>
      </SecondNav>
    </>
  )
}

export default SubNavBar
