import set from 'lodash.set'

// GOT FROM https://stackoverflow.com/questions/6491463/accessing-nested-javascript-objects-and-arrays-by-string-path

export const resolvePathInObject = (
  object: Record<string, unknown>,
  path: string,
): unknown => path.split('.').reduce((p, c) => (p && p[c]) || null, object)

export const setValueInPath = (
  object: Record<string, unknown>,
  path: string,
  newValue: unknown,
) => set(object, path, newValue)
