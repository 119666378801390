import React, { useContext } from 'react'

import { Typography, styled, Container } from '@deliveryhero/armor'

import SEO from '../../components/seo'
import 'react-toastify/dist/ReactToastify.min.css'
import { GlobalContext } from '../../global/Context'

const IndexPage: React.FC = () => {
  const { selectedCountryCode } = useContext(GlobalContext)

  const width = window.innerWidth
  const height = window.innerHeight

  const countryMapping = {
    sg: 'p_ufvvb51ptc',
    tw: 'p_bfogfx2ptc',
    uy: 'p_hp9gaq3ptc',
    ar: 'p_t58fin3ptc',
    at: 'p_0xrpdu2pzc',
    ec: 'p_8y9saj1qtc',
    cz: 'p_t23qoczrtc',
    my: 'p_icu3wvrwtc',
    sa: 'p_mhgmuo5pzc',
    ph: 'p_6nk8xt5pzc',
    bd: 'p_p5lo6u5pzc',
    hu: 'p_2k8dlw5pzc',
    pk: 'p_bzz20w5pzc',
    th: 'p_4h1f3y5pzc',
    se: 'p_p35zfz5pzc',
    hk: 'p_5jbhyz5pzc',
  }

  const FloatingSection = styled.section`
    position: fixed;
    bottom: 0;
    left: 0;
    height: 50px;
    width: 100%;
    background: #fff;
    display: flex;
    align-items: center;
    justify-content: center;
  `

  let content = null

  if (!selectedCountryCode) {
    content = (
      <>
        <Typography textAlign="center" pageTitle>
          Reports
        </Typography>
        <Typography sectionTitle textAlign="center">
          Select a country to continue
        </Typography>
      </>
    )
  } else if (!countryMapping[selectedCountryCode]) {
    content = (
      <>
        <Typography textAlign="center" pageTitle>
          Reports
        </Typography>
        <Typography sectionTitle textAlign="center">
          Feature is not available yet in your country
        </Typography>
      </>
    )
  } else {
    content = (
      <iframe
        title="asd"
        width={width}
        height={height}
        src={`https://datastudio.google.com/embed/reporting/b902def9-8591-4a10-8f64-31616bdbe0e9/page/${countryMapping[selectedCountryCode]}`}
        frameBorder="0"
        style={{ border: 0 }}
        allowFullScreen
      />
    )
  }

  return (
    <>
      <SEO title="Reports" />
      <Container style={{ marginTop: 20 }}>{content}</Container>

      <FloatingSection>
        <Typography label align="center">
          For the reports to work, you need to be logged in with a{' '}
          <b>deliveryhero.com</b> account and if you have an ad blocker, you
          need to disable it
        </Typography>
      </FloatingSection>
    </>
  )
}

export default IndexPage
