import { sortBy } from 'lodash'
import React, { useEffect, useState } from 'react'

import { Flex, List, ListItem, Skeleton } from '@deliveryhero/armor'

import { useGetLocations } from '../../arara.api'

import { LocationsTable } from './LocationsTable'
import { stringToRules } from './utils'

type IProps = {
  rules: string | undefined
  countryId: string
  onRuleChange: (rule: LocationRule[]) => void
  filterSelected: boolean
}

export type LocationRule = {
  name: string
  locationId: string
  workflow: string | undefined
  selected: boolean
}

export const LocationsInput = ({
  rules,
  countryId,
  onRuleChange,
  filterSelected,
}: IProps) => {
  const selectedRules = stringToRules(rules)

  const { locations, loading } = useGetLocations(countryId)

  if (loading) {
    return (
      <List wide>
        {Array.from(Array(10)).map((it, index) => (
          // eslint-disable-next-line react/no-array-index-key
          <ListItem key={index} enableSecondaryItem={false}>
            <Flex>
              <Skeleton animated /> <Skeleton animated width="250px" />
            </Flex>
          </ListItem>
        ))}
      </List>
    )
  }

  const handleSelectedRuleChange = (
    checked: boolean,
    location: LocationRule,
    order: string[],
  ) => {
    const newRulesSelected = sortBy(
      [
        ...Array.from(selectedRules.values()).filter(
          it => it.locationId !== location.locationId,
        ),
        // workflow will be removed as pages are updated to use the arara locations
        // we keep it in the type just to give it visibility.
        {
          ...location,
          workflow:
            selectedRules.get(location.locationId)?.workflow ?? undefined,
          selected: checked,
        },
      ],
      it => order.findIndex(locationId => locationId === it.locationId),
    )

    onRuleChange(newRulesSelected.filter(it => it.selected))
  }

  const handleOrderChange = (order: string[]) => {
    const newRulesSelected = sortBy(Array.from(selectedRules.values()), it =>
      order.findIndex(locationId => locationId === it.locationId),
    )
    onRuleChange(newRulesSelected.filter(it => it.selected))
  }

  return (
    <List wide>
      {locations && (
        <LocationsTable
          araraLocations={locations}
          locations={selectedRules}
          handleSelectedRuleChange={handleSelectedRuleChange}
          handleOrderChange={handleOrderChange}
          filterSelected={filterSelected}
        />
      )}
    </List>
  )
}
