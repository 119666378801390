import { Block } from '../utils/renderer'

export type Relationship = {
  id: string
  resource: string
  restRealKey?: string
}

export type Country = {
  id: number
  code: string
  name: string
}

export type LandingPage = {
  id?: string
  name?: string
  landingPageContents?: Relationship[]
  theme?: Relationship
  restOperation: string
  baseUrl?: string
}

export type Theme = {
  id?: string
  name?: string
  brandCode?: string
  primaryColor?: string
  secondaryColor?: string
  logo?: string
  secondaryLogo?: string
  logoIcon?: string
  enabled?: boolean
  restOperation?: string
  bgImageTop?: string
  bgImageLeft?: string
  bgImageRight?: string
  bgImageBottom?: string
}

export type SEO = {
  id?: string
  title?: string
  tags?: string[]
  description?: string
}

export type LandingPageContent = {
  id: string
  fields?: Relationship[]
  vehicles?: Relationship[]
  language?: Relationship
  workflow?: Relationship
  restOperation?: string
  content?: {
    structure: Block
  }
  seo?: Relationship
}

export type Field = {
  id?: string
  restOperation?: string
  name?: string
  text?: string
  value?: string
  settings?: {
    validations?: string[]
    datePicker?: boolean
    options?: { label: string; value: string }[]
  }
  enabled?: boolean
  fieldType?: string
  orderIndex: number
}

export type Language = {
  id: string
  name: string
}

export type Workflow = {
  id?: string
  rules?: string
  enabled?: boolean
  restOperation?: string
}

export type Vehicle = {
  id?: string
  restOperation?: string
}

// TODO: Research how to make the resources key optional?
export type KeyedResponseData = {
  landingPages?: {
    [id: string]: LandingPage
  }
  themes?: {
    [id: string]: Theme
  }
  landingPageContents?: {
    [id: string]: LandingPageContent
  }
  fields?: {
    [id: string]: Field
  }
  languages?: {
    [id: string]: Language
  }
  workflows?: {
    [id: string]: Workflow
  }
  vehicles?: {
    [id: string]: Vehicle
  }
  seos?: {
    [id: string]: SEO
  }
}

export const emptyKeyedResponseData: KeyedResponseData = {
  landingPages: {},
  themes: {},
  landingPageContents: {},
  fields: {},
  vehicles: {},
  languages: {},
  seos: {},
}

export type DodoFile = {
  id: string
  name: string
  type: string
  value: string
  enabled: boolean
  landingPageContentId?: string
  resourceType?: string
  resourceId?: string
}

export type NewDodoFile = Omit<DodoFile, 'id'> & { contents: File }
