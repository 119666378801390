import { backofficeOnlyConfig } from '../config/global'
import { Theme } from '../global/types'
import { getUseAPI } from '../utils/api'
import { deserializer } from '../utils/deserializer'
import { makeRequest } from '../utils/requests'
import { snakecase } from '../utils/strings'

const { useAPI, mutate } = getUseAPI(backofficeOnlyConfig.dodoApiBaseUrl)

export const useGetTheme = (themeId: string) => {
  const { data, error, loading } = useAPI<Theme>(`/themes/${themeId}`)

  return {
    data: data ? (deserializer(data) as Theme) : data,
    error,
    loading,
  }
}

export const patchTheme = (
  id: string,
  properties: Record<string, string | boolean>,
) =>
  makeRequest('patch', `/themes/${id}`, {
    baseURL: backofficeOnlyConfig.dodoApiBaseUrl,
    data: Object.keys(properties).reduce(
      (previous, current) => ({
        ...previous,
        [snakecase(current)]: properties[current],
      }),
      {},
    ),
  })

export const refreshTheme = (id: string) => mutate(`/themes/${id}`)
