import { Link } from 'gatsby'
import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'

import {
  Button,
  Card,
  Dialog,
  DialogTitle,
  FileCard,
  FileUploader,
  Flex,
  FlexItem,
  Grid,
  GridItem,
  styled,
  TextInput,
  Typography,
} from '@deliveryhero/armor'
import { ArrowLeftIcon } from '@deliveryhero/armor-icons'

import { landingPageFactory, themeFactory } from '../../mocks/factories'
import { LandingPageRenderer } from '../components/LandingPageRenderer'
import { toastError } from '../components/toasts/Error'
import { toastSuccess } from '../components/toasts/Success'
import { normalizeLandingPage } from '../containers/landing-page/api'
import { LandingPageResponse } from '../containers/landing-page/api.schema'
import { getLandingPageRequestDenormalizer } from '../containers/landing-page/selectors'
import { DodoFile, NewDodoFile, Theme } from '../global/types'
import { RegisterFn, useForm } from '../hooks/useForm'
import { FnComponent } from '../types'
import { optimisedImage } from '../utils/imageCompression'
import { prefixUrlIfRelative } from '../utils/prefixUrlIfRelative'
import { uploadFile } from '../utils/requests'

import { patchTheme, useGetTheme, refreshTheme } from './api'

const Header = styled.main`
  padding: 33px 40px 10.8px 40px;
  background-color: #fff;
  border-bottom: 1px solid #dbdbdb;
  width: 100%;
`

const LeftColumn = styled(GridItem)`
  background-color: #fff;
  padding: 33px 40px;
`

const RightColumn = styled(GridItem)`
  padding: 2em;
  text-align: right;
  overflow: scroll;
  height: 800px;
`

const ColorIcon = styled(FlexItem)`
  background-color: ${props => props.color};
  border-radius: 4px;
  width: 54px;
  height: 44px;
  margin-right: 16px;
  border: 1px solid #dbdbdb;
`
const SaveButton = styled(Button)`
  margin-top: -1em;
  margin-bottom: 1em;
`

const InputRow: FnComponent<{
  color: string
  title: string
  inputHandler: ReturnType<RegisterFn>
}> = ({ color, title, inputHandler }) => (
  <section style={{ marginBottom: '32px' }}>
    <Typography subSectionTitle marginBottom="16px">
      {title}
    </Typography>
    <Flex marginBottom="32px">
      <ColorIcon color={color} />
      <FlexItem flexBasis="100%">
        <TextInput wide {...inputHandler} />
      </FlexItem>
    </Flex>
  </section>
)

const FileInputRow: FnComponent<{
  title: string
  description: string
  previewURL: string
  onChange: (files: File[]) => void
}> = ({ title, description, previewURL, onChange }) => (
  <Flex direction="column" marginBottom="32px">
    <Typography subSectionTitle marginBottom="8px">
      {title}
    </Typography>
    <Typography paragraph medium color="#6B6B6B" marginBottom="16px">
      {description}
    </Typography>
    <FileCard fileName={title} imageSrc={previewURL} fileSize={1024} />
    <FileUploader
      onFilesSelect={onChange}
      marginTop="-6px"
      uploadButtonParams={{ secondary: true, small: true }}
    />
  </Flex>
)

const PRIMARY_COLOR = 'primaryColor'
const SECONDARY_COLOR = 'secondaryColor'
const LOGO_FILE = 'logo'
const SECONDARY_LOGO_FILE = 'secondaryLogo'
const ICON_FILE = 'icon'

const createDodoFile = (
  theme: Theme,
  name: string,
  file: File,
): NewDodoFile => ({
  type: file.type,
  enabled: true,
  name,
  contents: file,
  resourceType: 'dodo_themes',
  resourceId: theme.id,
  value: URL.createObjectURL(file),
})

export const ThemeComponent: FnComponent<{ themeId: string }> = ({
  themeId,
}) => {
  const { data: theme, loading, error } = useGetTheme(themeId)
  const [t] = useTranslation()

  const [logoFile, setLogoFile] = useState<File | undefined>()
  const [secondaryLogoFile, setsecondaryLogoFile] = useState<File | undefined>()
  const [iconFile, setIconFile] = useState<File | undefined>()
  const [isModalOpen, setIsModalOpen] = useState<boolean>(false)

  const { register, watch, getValues } = useForm()

  const logoURL = logoFile ? URL.createObjectURL(logoFile) : theme?.logo
  const secondaryLogoURL = secondaryLogoFile
    ? URL.createObjectURL(secondaryLogoFile)
    : theme?.secondaryLogo
  const iconURL = iconFile ? URL.createObjectURL(iconFile) : theme?.logoIcon
  const primaryColor = watch(PRIMARY_COLOR) || theme?.primaryColor
  const secondaryColor = watch(SECONDARY_COLOR) || theme?.secondaryColor

  const isFormChanged =
    !loading &&
    (primaryColor !== theme.primaryColor ||
      secondaryColor !== theme.secondaryColor ||
      logoURL !== theme.logo ||
      secondaryLogoURL !== theme.secondaryLogo ||
      iconURL !== theme.logoIcon)

  const rawLandingPage = normalizeLandingPage(
    landingPageFactory({
      theme: themeFactory({
        primary_color: primaryColor,
        secondary_color: secondaryColor,
        logo: logoURL,
        secondaryLogo: secondaryLogoURL,
        icon: iconURL,
      }),
      id: 'preview',
    }) as unknown as LandingPageResponse,
  )

  const landingPage = rawLandingPage
    ? getLandingPageRequestDenormalizer(
        {
          ...(rawLandingPage as Record<string, unknown>),
        },
        'preview',
        {},
      )
    : undefined

  const onSubmit = async () => {
    const data = getValues()
    let s3LogoFile: DodoFile | undefined
    let s3SecondaryLogoFile: DodoFile | undefined
    let s3IconFile: DodoFile | undefined

    try {
      if (logoFile) {
        s3LogoFile = await uploadFile(
          await optimisedImage(createDodoFile(theme, 'Logo', logoFile)),
        )
      }

      if (secondaryLogoFile) {
        s3SecondaryLogoFile = await uploadFile(
          await optimisedImage(
            createDodoFile(theme, 'SecondaryLogo', secondaryLogoFile),
          ),
        )
      }

      if (iconFile) {
        s3IconFile = await uploadFile(
          await optimisedImage(createDodoFile(theme, 'Icon', iconFile)),
        )
      }

      await patchTheme(theme.id, {
        ...Object.fromEntries(data),
        ...(s3LogoFile ? { logo: s3LogoFile.value } : {}),
        ...(s3SecondaryLogoFile
          ? { secondaryLogo: s3SecondaryLogoFile.value }
          : {}),
        ...(s3IconFile ? { logoIcon: s3IconFile.value } : {}),
      })
      toastSuccess(t('Themes.EditSuccess'))
      setLogoFile(undefined)
      setsecondaryLogoFile(undefined)
      setIconFile(undefined)
      refreshTheme(theme.id)
    } catch {
      toastError(t('Themes.EditFailure'))
    }
  }

  const onFilesSelect = (
    fileType: typeof LOGO_FILE | typeof ICON_FILE | typeof SECONDARY_LOGO_FILE,
    file: File,
  ) => {
    if (fileType === LOGO_FILE) {
      setLogoFile(file)
    } else if (fileType === ICON_FILE) {
      setIconFile(file)
    } else if (fileType === SECONDARY_LOGO_FILE) {
      setsecondaryLogoFile(file)
    }
  }

  if (loading) return <h1>{t('Themes.Loading')}</h1>
  if (error)
    return (
      <h1>
        {t('Themes.Error')} {error}
      </h1>
    )

  return (
    <>
      <Dialog
        open={isModalOpen}
        onClose={() => setIsModalOpen(false)}
        width="441px"
      >
        <DialogTitle>
          <Typography subSectionTitle>
            {t('Themes.ConfirmChangesTitle')}
          </Typography>
          <Typography paragraph color="#6B6B6B" marginTop="-16px">
            {t('Themes.ConfirmChangesDescription')}
          </Typography>
        </DialogTitle>
        <div
          style={{
            textAlign: 'right',
            width: '100%',
            paddingRight: '24px',
            paddingBottom: '24px',
          }}
        >
          <Button
            tertiary
            onClick={() => setIsModalOpen(false)}
            marginRight="48px"
          >
            {t('Themes.Cancel')}
          </Button>
          <Button
            onClick={() => {
              setIsModalOpen(false)
              onSubmit()
            }}
          >
            {t('Themes.ConfirmSave')}
          </Button>
        </div>
      </Dialog>
      <main id="theme-edit-page">
        <Header>
          <Flex>
            <FlexItem style={{ paddingTop: '0.75em', marginRight: '48px' }}>
              <Link to="/app/themes">
                <ArrowLeftIcon color="#1C5ECE" />
              </Link>
            </FlexItem>
            <FlexItem>
              <Typography pageTitle marginBottom="10px">
                {t('Themes.EditTheme')}
              </Typography>
              <Typography paragraph medium>
                {t('Themes.EditThemeDescription')}
              </Typography>
            </FlexItem>
            <FlexItem flexGrow={1} style={{ textAlign: 'end' }}>
              <img
                width="64px"
                height="64px"
                src={prefixUrlIfRelative(theme.logoIcon)}
                alt={theme.name}
              />
            </FlexItem>
          </Flex>
        </Header>
        <Grid height="800px">
          <LeftColumn xs={4}>
            <form>
              <InputRow
                title={t('Themes.PrimaryColor')}
                inputHandler={register(PRIMARY_COLOR, {
                  defaultValue: theme.primaryColor,
                })}
                color={watch(PRIMARY_COLOR) as string}
              />

              <InputRow
                title={t('Themes.SecondaryColor')}
                inputHandler={register(SECONDARY_COLOR, {
                  defaultValue: theme.secondaryColor,
                })}
                color={watch(SECONDARY_COLOR) as string}
              />

              <FileInputRow
                title={t('Themes.Logo')}
                description={t('Themes.LogoDescription')}
                onChange={files => onFilesSelect(LOGO_FILE, files[0])}
                previewURL={
                  logoFile
                    ? URL.createObjectURL(logoFile)
                    : prefixUrlIfRelative(theme.logo)
                }
              />

              <FileInputRow
                title={t('Themes.SecondaryLogo')}
                description={t('Themes.SecondaryLogoDescription')}
                onChange={files => onFilesSelect(SECONDARY_LOGO_FILE, files[0])}
                previewURL={
                  secondaryLogoFile
                    ? URL.createObjectURL(secondaryLogoFile)
                    : prefixUrlIfRelative(theme.secondaryLogo)
                }
              />

              <FileInputRow
                title={t('Themes.Icon')}
                description={t('Themes.IconDescription')}
                onChange={files => onFilesSelect(ICON_FILE, files[0])}
                previewURL={
                  iconFile
                    ? URL.createObjectURL(iconFile)
                    : prefixUrlIfRelative(theme.logoIcon)
                }
              />
            </form>
          </LeftColumn>
          <RightColumn xs={8}>
            <SaveButton
              onClick={() => setIsModalOpen(true)}
              disabled={!isFormChanged}
              small
            >
              {t('Themes.Save')}
            </SaveButton>
            <Card padding="1em">
              <LandingPageRenderer
                landingPage={landingPage}
                selectedLandingPageContentIdx={1}
                onChangeLandingPageContextIdx={() => null}
              />
            </Card>
          </RightColumn>
        </Grid>
      </main>
    </>
  )
}
