import React, { FC } from 'react'

import { Typography, colorGrey50 } from '@deliveryhero/armor'

type PropsType = {
  children?: React.ReactNode
  subtitle?: string
}

const TitleWithSubtitle: FC<PropsType> = ({ children, subtitle, ...props }) => (
  <div data-testid="title-with-subtitle" {...props}>
    <Typography subSectionTitle margin={0}>
      {children}
    </Typography>
    <Typography paragraph small color={colorGrey50} margin={0}>
      {subtitle}
    </Typography>
  </div>
)

export { TitleWithSubtitle }
export type { PropsType as TitleWithSubtitlePropsType }
