import { formatDistanceToNow, parseISO } from 'date-fns'
import { withPrefix } from 'gatsby'
import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'

import {
  Button,
  Dialog,
  DialogActions,
  DialogTitle,
  styled,
} from '@deliveryhero/armor'

import { LoadingSpinner } from '../../components/Atoms/LoadingSpinner'
import { LandingPageThumbnailSection } from '../../components/Organisms'
import { truncate } from '../../utils/strings'

import { deleteLandingPage } from './api'
import type { LandingPage } from './api'

const AllLandingPagesContainer = styled.div`
  padding: 2rem 1rem;
  width: 100%;
`

type LandingPagesHomeGridProps = {
  createDisabled: boolean
  handleCreate: () => void
  landingPages: LandingPage[]
}

export const LandingPagesHomeGrid = ({
  createDisabled,
  handleCreate,
  landingPages,
}: LandingPagesHomeGridProps) => {
  const { t } = useTranslation()

  const [activeId, setActiveId] = useState<string>()
  const [activeName, setActiveName] = useState<string>()
  const [dialogOpen, setDialogOpen] = useState(false)
  const [isDeleting, setIsDeleting] = useState(false)

  const openDialog = (id: string, name: string) => {
    setActiveId(id)
    setActiveName(name)
    setDialogOpen(true)
  }

  const closeDialog = () => {
    setActiveId(undefined)
    setActiveName(undefined)
    setDialogOpen(false)
  }

  const handleDelete = async () => {
    if (!activeId) {
      return
    }

    try {
      setIsDeleting(true)
      await deleteLandingPage(activeId)
      closeDialog()
    } finally {
      setIsDeleting(false)
    }
  }

  return (
    <AllLandingPagesContainer>
      <Dialog open={dialogOpen} disableCloseButton>
        <DialogTitle description={t('Home.DeleteAlert')}>
          {t('Home.Delete')} {`"${activeName}"?`}
        </DialogTitle>
        <DialogActions>
          <Button tertiary onClick={closeDialog}>
            {t('Home.Cancel')}
          </Button>
          <Button danger onClick={handleDelete}>
            {isDeleting ? (
              <LoadingSpinner width="30px" primaryColor="#ffffff" />
            ) : (
              t('Home.DeleteConfirmButton')
            )}
          </Button>
        </DialogActions>
      </Dialog>

      <LandingPageThumbnailSection
        createText={t('Home.CreateNewLandingPageButton')}
        createDisabled={createDisabled}
        handleCreate={handleCreate}
        thumbnails={landingPages.map(landingPage => ({
          id: landingPage.id,
          title: truncate(landingPage.name, 25),
          subtitle: `${t('Home.LastEdit')}: ${formatDistanceToNow(
            parseISO(landingPage.updated_at),
          )} ago`,
          // Disabled because this doesn't work with the new way to publish landing pages
          // publishText: t('LandingPage.Publish'),
          // handlePublish: async () => {
          //   if (landingPage.id && landingPage.landing_page_domain) {
          //     try {
          //       await publishLandingPage(
          //         landingPage.id,
          //         landingPage.landing_page_domain,
          //       )

          //       toastSuccess(
          //         `Landing Page "${landingPage.name}" is being published`,
          //       )
          //     } catch {
          //       // handled by request lib, do nothing
          //     }
          //   }
          // },
          image: withPrefix(landingPage.theme.logo),
          backgroundColor: landingPage.theme.secondary_color,
          menuElements: [
            {
              id: 'delete',
              label: (
                <span
                  tabIndex={0}
                  role="button"
                  onKeyDown={() => {
                    openDialog(landingPage.id, landingPage.name)
                  }}
                  onClick={() => {
                    openDialog(landingPage.id, landingPage.name)
                  }}
                >
                  {t('Home.Delete')}
                </span>
              ),
            },
          ],
        }))}
      />
    </AllLandingPagesContainer>
  )
}
