import { sortBy } from 'lodash'
import React, { useState } from 'react'
import { DragDropContext, Draggable, Droppable } from 'react-beautiful-dnd'
import { useTranslation } from 'react-i18next'

import {
  Button,
  ContentSwitcher,
  ContentSwitcherControl,
  ContextMenu,
  Dropdown,
  Pack,
  Switch,
  TextInput,
  IconButton,
  styled,
} from '@deliveryhero/armor'
import { DragIcon, EllipsisVerticalIcon } from '@deliveryhero/armor-icons'

import { Field, KeyedResponseData } from '../../../../global/types'
import { SUPPORTED_VALIDATIONS } from '../../../../utils/field-validations'
import { snakeToTitleCase } from '../../../../utils/strings'
import { DenormalizedField, LandingPageContentType } from '../../selectors'
import { moveElement } from '../LocationsInput/utils'

type HandleFieldChange = (
  _: string,
  id: string,
  property: keyof Field,
  value: Field[keyof Field],
) => void

type IProps = {
  landingPageContents: LandingPageContentType[]
  selectedLanguage: number
  setSelectedLanguage: (index: number) => void
  handleFieldChange: HandleFieldChange
  onAddNewField: () => void
  handleChanges: (changes: KeyedResponseData) => void
}

const BorderlessTD = styled.td`
  border: none;
`

const StringFieldCell = ({
  field,
  handleFieldChange,
}: {
  field: Field
  handleFieldChange: HandleFieldChange
}) => (
  <BorderlessTD>
    <TextInput
      width="100%"
      data-testid-input={field.id}
      disabled={field.restOperation === 'POST' || !field.enabled}
      value={`${field.text}`}
      label={snakeToTitleCase(field.name)}
      onChange={e =>
        handleFieldChange(field.name, field.id, 'text', e.target.value)
      }
    />
  </BorderlessTD>
)

const AgeFieldCell = ({
  field,
  t,
  handleFieldChange,
}: {
  field: Field
  t: (s: string) => string
  handleFieldChange: HandleFieldChange
}) => (
  <BorderlessTD style={{ width: '60%' }}>
    <TextInput
      width="100%"
      data-testid-input={field.id}
      disabled={!field.enabled}
      label={t('Settings.General.MinimumAge')}
      onChange={e =>
        handleFieldChange(field.name, field.id, 'text', e.target.value)
      }
      value={field.text}
      marginBottom="4px"
    />
    <Pack style={{ fontSize: '14px', columnGap: '14px' }}>
      {t('Settings.General.YesNo')}
      <Switch
        data-testid={`${field.name}-switch`}
        checked={field.settings.datePicker}
        disabled={!field.enabled}
        onChange={e =>
          handleFieldChange(field.name, field.id, 'settings', {
            ...field.settings,
            datePicker: e.target.checked,
          })
        }
      />
      {t('Settings.General.DatePicker')}
    </Pack>
  </BorderlessTD>
)

const FieldRow = ({
  field,
  validationOptions,
  handleFieldChange,
  index,
  moveToEnd,
  moveToStart,
  isLast,
}: {
  field: DenormalizedField
  validationOptions: { label: string; value: string }[]
  handleFieldChange: HandleFieldChange
  index: number
  moveToStart: () => void
  moveToEnd: () => void
  isLast: boolean
}) => {
  const [open, setOpen] = useState(false)
  const [t] = useTranslation()
  const onValidationChange =
    (it: Field) => (e: React.ChangeEvent<HTMLInputElement>) => {
      handleFieldChange(it.name, it.id, 'settings', {
        ...field.settings,
        validations: [e.target.value.toString()],
      })
    }
  return (
    <Draggable key={field.id} draggableId={field.id} index={index}>
      {(provided, { isDragging }) => (
        <tr
          {...provided.draggableProps}
          ref={provided.innerRef}
          style={{
            ...provided.draggableProps.style,
            background: 'white',
            boxShadow: isDragging ? '0px 2px 6px rgba(0, 0, 0, 0.1)' : '',
            verticalAlign: field.name === 'age' ? 'top' : 'middle',
          }}
        >
          <BorderlessTD {...provided.dragHandleProps}>
            <DragIcon marginTop={field.name === 'age' ? '13px' : 0} />
          </BorderlessTD>
          {field.name === 'age' ? (
            <AgeFieldCell
              field={field}
              t={t}
              handleFieldChange={handleFieldChange}
            />
          ) : (
            <StringFieldCell
              field={field}
              handleFieldChange={handleFieldChange}
            />
          )}
          <BorderlessTD>
            <Dropdown
              disabled={!field.enabled || field.name === 'age'}
              data-testid="field-container-validation-dropdown"
              placeholder={t('Settings.General.Validation')}
              options={validationOptions}
              onChange={onValidationChange(field)}
              value={field.settings.validations?.[0] ?? 'none'}
            />
          </BorderlessTD>
          <BorderlessTD>
            <ContextMenu
              open={open}
              trigger={
                <IconButton
                  data-testid={`field-action-menu-${index}`}
                  marginTop={field.name === 'age' ? '7.5px' : 0}
                >
                  <EllipsisVerticalIcon large color="#3F53B9" />
                </IconButton>
              }
              onOpenChange={setOpen}
              menuElements={[
                {
                  id: 'toTop',
                  label: t('Settings.Locations.BringToTop'),
                  props: {
                    disabled: index === 0,
                    onClick: () => {
                      moveToStart()
                      setOpen(false)
                    },
                  },
                },
                {
                  id: 'toBottom',
                  label: t('Settings.Locations.BringToBottom'),
                  props: {
                    disabled: isLast,
                    onClick: () => {
                      moveToEnd()
                      setOpen(false)
                    },
                  },
                },
                {
                  id: 'save',
                  label: !field.enabled
                    ? t('Settings.General.Action.Enable')
                    : t('Settings.General.Action.Disable'),
                  props: {
                    onClick: () => {
                      handleFieldChange(
                        field.name,
                        field.id,
                        'enabled',
                        !field.enabled,
                      )
                      setOpen(false)
                    },
                  },
                },
              ]}
            />
          </BorderlessTD>
        </tr>
      )}
    </Draggable>
  )
}

export const MainFieldsSwitch: React.FC<IProps> = ({
  landingPageContents,
  setSelectedLanguage,
  selectedLanguage,
  handleFieldChange,
  onAddNewField,
  handleChanges,
}) => {
  const [t] = useTranslation()
  const validationOptions = SUPPORTED_VALIDATIONS.map(validation => ({
    label: t(`Settings.General.${validation}`),
    value: validation,
  }))

  const sortedFields = sortBy(
    landingPageContents[selectedLanguage].fields,
    f => f.orderIndex,
  )

  const moveField = (from, to) => {
    const oldOrder = sortedFields.map(field => field.id)
    const newOrder = moveElement(oldOrder, from, to)

    const fieldChanges = {}

    for (let i = 0; i < newOrder.length; i += 1) {
      const fieldId = newOrder[i]
      const oldIndex = oldOrder.findIndex(it => it === fieldId)
      if (oldIndex !== i) {
        fieldChanges[fieldId] = { orderIndex: i, restOperation: 'PATCH' }
      }
    }
    handleChanges({ fields: fieldChanges })
  }

  const handleDragEnd = result => {
    moveField(result.source.index, result.destination.index)
  }

  const lastOrderIndex = Math.max(...sortedFields.map(f => f.orderIndex))

  return (
    <>
      <ContentSwitcher
        onSwitchNameChange={(switchName: unknown) => {
          setSelectedLanguage(switchName as number)
        }}
        marginBottom={4}
        width={50}
        defaultSwitchName={`${selectedLanguage}`}
      >
        {landingPageContents.map((it, i) => (
          <ContentSwitcherControl switchName={`${i}`}>
            {it.language.name}
          </ContentSwitcherControl>
        ))}
      </ContentSwitcher>
      <DragDropContext onDragEnd={handleDragEnd}>
        <table>
          <thead style={{ fontSize: '14px', fontWeight: 'bold' }}>
            <tr>
              <BorderlessTD style={{ width: '20px' }} />
              <BorderlessTD style={{ width: '60%' }}>
                {t('Settings.General.Description')}
              </BorderlessTD>
              <BorderlessTD style={{ width: '30%' }}>
                {t('Settings.General.Validation')}
              </BorderlessTD>
              <BorderlessTD>{t('Settings.General.Actions')}</BorderlessTD>
            </tr>
          </thead>
          <Droppable droppableId="rows" direction="vertical">
            {provided => (
              <tbody {...provided.droppableProps} ref={provided.innerRef}>
                {sortedFields.map(field => (
                  <FieldRow
                    key={field.id}
                    field={field}
                    index={field.orderIndex}
                    validationOptions={validationOptions}
                    handleFieldChange={handleFieldChange}
                    moveToStart={() => moveField(field.orderIndex, 0)}
                    moveToEnd={() =>
                      moveField(field.orderIndex, lastOrderIndex)
                    }
                    isLast={field.orderIndex === lastOrderIndex}
                  />
                ))}
              </tbody>
            )}
          </Droppable>
        </table>
        <Button secondary onClick={onAddNewField}>
          {t('Settings.General.AddNewField')}
        </Button>
      </DragDropContext>
    </>
  )
}
