import React, { useCallback, useMemo, useReducer } from 'react'

import { GlobalState, GlobalContext } from './Context'

export const GlobalProvider = ({ children }) => {
  const [state, dispatch] = useReducer(
    (previousState: GlobalState, action) => {
      switch (action.type) {
        case 'SET_COUNTRY':
          return {
            ...previousState,
            selectedCountryCode: action.payload,
          }
        default:
          return previousState
      }
    },
    {
      selectedCountryCode: undefined,
    },
  )

  const setSelectedCountry = useCallback(
    (newCountry: string) => {
      dispatch({
        type: 'SET_COUNTRY',
        payload: newCountry,
      })
    },
    [dispatch],
  )

  const contextValue = useMemo(
    () => ({
      selectedCountryCode: state.selectedCountryCode,
      setSelectedCountry,
    }),
    [state.selectedCountryCode, setSelectedCountry],
  )

  return (
    <GlobalContext.Provider value={contextValue}>
      {children}
    </GlobalContext.Provider>
  )
}
