import { backofficeOnlyConfig } from '../../config/global'
import { Theme } from '../../global/types'
import { getUseAPI } from '../../utils/api'
import { deserializer } from '../../utils/deserializer'
import { makeRequest } from '../../utils/requests'

import { LandingPageResponse, Language } from './api.schema'

const { useAPI, mutate } = getUseAPI(backofficeOnlyConfig.dodoApiBaseUrl)

const mergeData = (data, resourceType: string, resource) => ({
  ...data,
  [resourceType]: {
    ...data[resourceType],
    [resource.id]: resource,
  },
})

export const normalizeLandingPage = (response: LandingPageResponse) => {
  let data = {}

  const landingPageContents = response.landing_page_contents.map(
    landingPageContent => {
      // TODO: MOCK
      // eslint-disable-next-line no-param-reassign
      landingPageContent.fields = landingPageContent.fields
        .map(field => ({
          ...field,
          value: '',
          // This special cases for age will be addressed later in the Backend, but right now it's only needed for age fields
          fieldType:
            field.name === 'age' || field.field_type === 'radio'
              ? 'radio'
              : 'string',
          settings:
            field.name === 'age' || field.field_type === 'radio'
              ? {
                  ...field.settings,
                  options: [
                    { label: 'LandingPage.Yes', value: 'yes' },
                    { label: 'LandingPage.No', value: 'no' },
                  ],
                }
              : { ...field.settings },
        }))
        .sort((a, b) => a.order_index - b.order_index)

      data = mergeData(
        data,
        'languages',
        deserializer(landingPageContent.language),
      )

      data = mergeData(data, 'seos', deserializer(landingPageContent.seo))

      data = mergeData(
        data,
        'workflows',
        deserializer(landingPageContent.workflow),
      )

      const newLandingPageContent = {
        ...deserializer(landingPageContent),
        language: {
          id: landingPageContent.language.id,
          resource: 'languages',
        },
        fields: landingPageContent.fields.map(field => {
          data = mergeData(data, 'fields', deserializer(field))
          return {
            id: field.id,
            resource: 'fields',
          }
        }),
        vehicles: landingPageContent.vehicles.map(vehicle => {
          data = mergeData(data, 'vehicles', deserializer(vehicle))
          return {
            id: vehicle.id,
            resource: 'vehicles',
          }
        }),
        workflows: {
          id: landingPageContent.workflow.id,
          resource: 'workflows',
        },
        seo: {
          id: landingPageContent.seo.id,
          resource: 'seos',
        },
      }

      data = mergeData(data, 'landingPageContents', newLandingPageContent)

      return {
        resource: 'landingPageContents',
        id: landingPageContent.id,
      }
    },
  )

  data = mergeData(data, 'themes', deserializer(response.theme))

  data = mergeData(data, 'landingPages', {
    ...deserializer(response),
    theme: {
      resource: 'themes',
      id: response.theme.id,
    },
    landingPageContents,
  })

  return data
}

export const useGetThemes = () => {
  const { data, error, loading } = useAPI(`/themes`)

  let newData: { themes: Record<string, Theme> } = { themes: {} }

  // @ts-expect-error API types missing
  data?.themes?.forEach(theme => {
    newData = mergeData(newData, 'themes', deserializer(theme))
  })

  return { data: newData.themes, error, loading }
}

export const useGetLandingPage = (landingPageId: string) => {
  const url = `/landing_pages/${landingPageId}`
  const { data, error, loading } = useAPI<LandingPageResponse>(url)

  return {
    data: data ? normalizeLandingPage(data) : data,
    error,
    loading,
    mutateLandingPage: () => mutate(url),
  }
}

export const revalidateLandingPage = (landingPageId: string) => {
  mutate(`/landing_pages/${landingPageId}`)
}

export const publishLandingPage = (
  landingPageId: string,
  landingPageDomain: string,
  publicationConfig: Record<string, string | number | boolean>,
) =>
  makeRequest('post', `/landing_pages/${landingPageId}/publish`, {
    baseURL: backofficeOnlyConfig.dodoApiBaseUrl,
    data: {
      domain: `${landingPageDomain}`,
      env_config: publicationConfig,
    },
  })

type PartialLandingPageContents = Partial<{ rank: number }>

export const patchUpdateLandingPageContents = (
  contentId: string,
  rawData: PartialLandingPageContents,
) =>
  makeRequest('patch', `/landing_page_contents/${contentId}`, {
    baseURL: backofficeOnlyConfig.dodoApiBaseUrl,
    data: rawData,
  })

export const deleteLandingPageContent = (landingPageContentId: string) =>
  makeRequest('delete', `/landing_page_contents/${landingPageContentId}`, {
    baseURL: backofficeOnlyConfig.dodoApiBaseUrl,
  })

export const useGetLanguages = () => {
  const url = `/languages`
  const { data, error, loading } = useAPI<{ languages: Language[] } | null>(url)

  return {
    data,
    error,
    loading,
    mutateLanguages: () => mutate(url),
  }
}

export const addNewLanguage = (landingPageId: string, languageCode: string) =>
  makeRequest(
    'post',
    `/landing_pages/${landingPageId}/languages/${languageCode}`,
    {
      baseURL: backofficeOnlyConfig.dodoApiBaseUrl,
    },
  )
