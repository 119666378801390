import React from 'react'
import { useTranslation } from 'react-i18next'

import { Button, Typography, Flex, FlexItem, styled } from '@deliveryhero/armor'

import mountainsBackground from './images/mountains.svg'
import sunBackground from './images/sun.svg'

const ContainerWithBackground = styled.div`
  background: url(${mountainsBackground}) no-repeat bottom/100% fixed,
    url(${sunBackground}) right top no-repeat;
  height: calc(100vh - 55px);
`

const CenterAlignedTypography = styled(Typography)`
  text-align: center;
  max-width: 41rem;
  margin: auto;
`

const Title = styled(CenterAlignedTypography)`
  @media only screen and (max-width: 1060px) {
    padding: 16rem 0 1.5rem 0;
  }

  padding: 10rem 0 1.5rem 0;
`

const Description = styled(CenterAlignedTypography)`
  color: #717171;
`

const CreateButton = styled(Button)`
  font-size: 0.875rem;
  margin-top: 3rem;
`

type CreateLandingPageSplashProps = {
  createDisabled: boolean
  handleCreate: () => void
}

export const CreateLandingPageSplash = ({
  createDisabled,
  handleCreate,
}: CreateLandingPageSplashProps) => {
  const { t } = useTranslation()

  return (
    <ContainerWithBackground>
      <Title pageTitle>{t('Home.WelcomeToDodo')}</Title>
      <Description paragraph large>
        {t('Home.DodoDescription')} <br />
        <br />
        {t('Home.StartCreatingNewFantastic')}
      </Description>

      <Flex>
        <FlexItem flexGrow={1} />
        <FlexItem>
          <CreateButton disabled={createDisabled} onClick={handleCreate}>
            {t('Home.CreateNewLandingPageButton')}
          </CreateButton>
        </FlexItem>
        <FlexItem flexGrow={1} />
      </Flex>
    </ContainerWithBackground>
  )
}
