import React from 'react'
import { useTranslation } from 'react-i18next'

import { Grid, Tag, TextInput, styled } from '@deliveryhero/armor'

const VerticalGrid = styled(Grid)`
  flex-direction: column;
`

const StyledTag = styled(Tag)`
  margin-right: 8px;
  margin-bottom: 8px;
`

const TagInput = styled(TextInput)`
  margin-bottom: 8px;
`

export const TagManager: React.FC<{
  readonly?: boolean
  tags: string[]
  onTagChange: (tags: string[]) => void
}> = ({ readonly, tags, onTagChange }) => {
  const [t] = useTranslation()

  const onRemove = (tagToRemove: string) => {
    onTagChange(tags.filter(tag => tag !== tagToRemove))
  }

  const onKeyPress = (e: React.KeyboardEvent<HTMLInputElement>) => {
    if (
      e.keyCode !== 13 ||
      e.target.value.trim() === '' ||
      tags.includes(e.target.value)
    )
      return
    onTagChange(tags.concat([e.target.value]))
    e.target.value = ''
  }

  return (
    <VerticalGrid>
      {!readonly && (
        <TagInput
          placeholder={t('Settings.Seo.TagManagerPlaceholder')}
          onKeyDown={onKeyPress}
        />
      )}
      <section>
        {tags.map(tag => (
          <StyledTag
            key={tag}
            label={tag}
            deleteOption="enabled"
            onClose={() => onRemove(tag)}
          />
        ))}
      </section>
    </VerticalGrid>
  )
}
