import React, { FC } from 'react'

import {
  styled,
  Card,
  ContextMenu,
  IconButton,
  ContextMenuPropsType,
} from '@deliveryhero/armor'
import { EllipsisVerticalIcon } from '@deliveryhero/armor-icons'

type PropsType = {
  backgroundColor?: string
  image?: string
  menuColor?: string
  showTrigger?: boolean
} & ContextMenuPropsType

const ContextMenuStyled = styled.div`
  padding-top: 6px;
  grid-area: menu;
`
const CardWithMenuWrapper = styled(Card)<PropsType>`
  vertical-align: top;
  border-radius: 12px;
  display: inline-grid;
  width: 312px;
  height: 216px;
  grid-template:
    [row1-start] '. . menu' 40px [row1-end]
    [row2-start] '. main .' auto [row2-end]
    [row2-start] '. . .' 40px [row2-end]
    / 40px auto 40px;
  background-color: ${props => props.backgroundColor};
  box-shadow: 0px 2px 28px 0px #0000001f;
`
const MainStyled = styled.div`
  max-width: 100%;
  max-height: 100%;
  grid-area: main;
  align-self: center;
  justify-self: center;
  text-align: center;
`
const CardWithMenu: FC<PropsType> = ({
  image,
  menuColor,
  menuElements,
  showTrigger = true,
  children,
  ...props
}) => (
  <CardWithMenuWrapper data-testid="card-with-menu" {...props}>
    <ContextMenuStyled>
      <ContextMenu
        trigger={
          showTrigger && (
            <IconButton>
              <EllipsisVerticalIcon large color={menuColor} />
            </IconButton>
          )
        }
        menuElements={menuElements}
      />
    </ContextMenuStyled>
    {image ? (
      <MainStyled as="img" src={image} />
    ) : (
      <MainStyled>{children}</MainStyled>
    )}
  </CardWithMenuWrapper>
)

export { CardWithMenu }
export type { PropsType as CardWithMenuPropsType }
