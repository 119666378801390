import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'

import {
  Checkbox,
  Dialog,
  DialogContent,
  DialogTitle,
  TextInput,
  Typography,
  DialogActions,
  Button,
} from '@deliveryhero/armor'

export const PopoverNewField = ({
  isOpen,
  onCreate,
  onCancel,
}: {
  isOpen: boolean
  onCreate: (
    name: string,
    type: string,
    settings: Record<string, unknown>,
  ) => void
  onCancel: () => void
}) => {
  const [isYesOrNoQuestion, setIsYesOrNoQuestion] = useState(false)
  const [t] = useTranslation()

  return (
    <Dialog open={isOpen} maxWidth="xs" onClose={onCancel}>
      <DialogTitle>{t('Settings.General.AddNewField')}</DialogTitle>

      <form
        onSubmit={e => {
          e.preventDefault()
          onCreate(
            e.target.name.value,
            isYesOrNoQuestion ? 'radio' : 'string',
            {
              options: [
                { label: 'LandingPage.Yes', value: 'yes' },
                { label: 'LandingPage.No', value: 'no' },
              ],
            },
          )
        }}
      >
        <DialogContent>
          <TextInput
            label="name"
            wide
            name="name"
            data-testid="popover-new-field-name"
          />
          <Checkbox
            checked={isYesOrNoQuestion}
            onChange={() => setIsYesOrNoQuestion(!isYesOrNoQuestion)}
            name="yesOrNoQuestion"
            data-testid="popover-new-field-yesOrNoQuestionCheckbox"
            label={
              <Typography paragraph medium>
                {t('Settings.General.IsThisAYesOrNoQuestion')}
              </Typography>
            }
          />
        </DialogContent>
        <DialogActions>
          <Button tertiary onClick={onCancel}>
            {t('Home.Cancel')}
          </Button>
          <Button type="submit">{t('Settings.Create')}</Button>
        </DialogActions>
      </form>
    </Dialog>
  )
}
