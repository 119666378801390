import { FormEvent, useRef, useState } from 'react'

export type RegisterFn = (
  name: string,
  options?: { selector?: (e: any) => string; defaultValue?: string },
) => {
  name: string
  onChange: (e: any) => void
  defaultValue?: string
}

type UseForm = (defaultValues?: Record<string, string>) => {
  register: RegisterFn
  handleSubmit: (
    callback: (data: Map<string, string | boolean>) => void,
  ) => (e: FormEvent<HTMLFormElement>) => void
  watch: (name: string) => string | boolean
  setValue: (name: string, value: string) => void
  getValues: () => Map<string, string | boolean>
}

type UseFormState = {
  fields: Map<string, string | boolean>
  watching: Map<string, string | boolean>
}

export const useForm: UseForm = defaultValues => {
  const myState = useRef<UseFormState>()
  const [_, setFormState] = useState({ renderCount: 0 })

  if (!myState.current) {
    myState.current = {
      fields: new Map(),
      watching: new Map(),
    }

    if (defaultValues) {
      Object.keys(defaultValues).forEach(key =>
        myState.current.fields.set(key, defaultValues[key]),
      )
    }
  }

  return {
    register: (name, { selector, defaultValue } = {}) => {
      if (!myState.current.fields.has(name) && defaultValue)
        myState.current.fields.set(name, defaultValue)

      return {
        name,
        onChange: (e: any) => {
          myState.current.fields.set(
            name,
            selector ? selector(e) : e.target.value,
          )

          if (myState.current.watching.has(name)) {
            setFormState(state => ({
              ...state,
              renderCount: state.renderCount,
            }))
          }
        },
        defaultValue: defaultValue || '',
      }
    },
    handleSubmit: callback => e => {
      e.preventDefault()
      callback(myState.current.fields)
    },
    watch: (name: string) => {
      if (!myState.current.watching.has(name)) {
        myState.current.watching.set(name, true)
      }

      return myState.current.fields.get(name)
    },
    setValue: (name: string, value: string) => {
      if (myState.current.fields.has(name)) {
        myState.current.fields.set(name, value)
      }
    },
    getValues: () => myState.current.fields,
  }
}
