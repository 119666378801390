import { backofficeOnlyConfig } from '../../config/global'
import { getUseAPI } from '../../utils/api'
import { makeRequest } from '../../utils/requests'
import { landingPageTemplate } from '../landing-page/landing-page'

// TODO: extract to global types file
// The keys are currently in snake_case
// and do not match the global file

/* eslint-disable camelcase */

export type LandingPage = {
  id: string
  name: string
  updated_at: string
  landing_page_domain: string
  theme: {
    id: string
    primary_color: string
    secondary_color: string
    logo_icon: string
    logo: string
  }
}

const { useAPI, mutate } = getUseAPI(backofficeOnlyConfig.dodoApiBaseUrl)

export const useGetAllLandingPages = () =>
  useAPI<{ landing_pages: LandingPage[] }>('/landing_pages')

/* eslint-enable camelcase */

export const deleteLandingPage = async (landingPageId: string) => {
  await makeRequest('delete', `/landing_pages/${landingPageId}`, {
    baseURL: backofficeOnlyConfig.dodoApiBaseUrl,
  })

  await mutate('/landing_pages')
}

export const createLandingPage = (
  countryCode: string,
  name: string,
  path: string,
) =>
  makeRequest<LandingPage>('post', '/landing_pages', {
    baseURL: backofficeOnlyConfig.dodoApiBaseUrl,
    data: {
      name,
      base_url: path,
      country_id: countryCode,
      default_content: landingPageTemplate,
      theme_id: 'foodora',
    },
  })
