export const capitalize = (string: string) =>
  `${string.charAt(0).toUpperCase()}${string.slice(1)}`

export const snakecase = (string: string) =>
  string.replace(/([A-Z])/g, (_, char) => `_${char.toLowerCase()}`)

export const snakeToCamel = (str: string) =>
  str.replace(/([-_][a-zA-Z])/g, group =>
    group.toUpperCase().replace('-', '').replace('_', ''),
  )

export const snakeToTitleCase = (string: string) =>
  string
    .split('_')
    .map(s => capitalize(s))
    .join(' ')

export const truncate = (string: string, length: number) => {
  if (string.length <= length) {
    return string
  }

  return `${string.substr(0, length - 3)}...`
}
