import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'

import {
  Dialog,
  DialogTitle,
  DialogActions,
  GridItem,
  List,
  ListItem,
  Button,
  Dropdown,
  Flex,
} from '@deliveryhero/armor'
import {
  ArrowDownIcon,
  ArrowUpIcon,
  DeleteIcon,
} from '@deliveryhero/armor-icons'

import { LoadingSpinner } from '../../../components/Atoms/LoadingSpinner'
import { toastError } from '../../../components/toasts/Error'
import {
  addNewLanguage,
  deleteLandingPageContent,
  patchUpdateLandingPageContents,
  revalidateLandingPage,
  useGetLanguages,
} from '../api'
import { DenormalizedLandingPage } from '../selectors'

type LanguageConfigProps = {
  landingPage: DenormalizedLandingPage
}

export const LanguageConfig = ({ landingPage }: LanguageConfigProps) => {
  const { t } = useTranslation()

  const { data: languageData, loading: languagesLoading } = useGetLanguages()

  const [newLanguage, setNewLanguage] = useState<null | string>(null)

  const [selectedLanguageIdToDelete, setSelectedLanguageIdToDelete] =
    useState<string>()
  const [deleteDialogOpen, setDeleteDialogOpen] = useState(false)

  const [saveLoading, setSaveLoading] = useState(false)

  const onAddNewLanguage = async () => {
    setSaveLoading(true)
    setNewLanguage(null)

    try {
      await addNewLanguage(landingPage.id, newLanguage)
      revalidateLandingPage(landingPage.id)
    } catch (e) {
      toastError(`${t('LandingPage.ErrorAddingNewLanguage')} - ${e.message}`)
    } finally {
      setSaveLoading(false)
    }
  }

  const handleDeleteLanguage = async () => {
    setSaveLoading(true)

    const landingPageContentToDelete = landingPage.landingPageContents.find(
      landingPageContent =>
        landingPageContent.language.id === selectedLanguageIdToDelete,
    )

    try {
      await deleteLandingPageContent(landingPageContentToDelete.id)

      revalidateLandingPage(landingPage.id)
    } catch (e) {
      toastError(`${t('LandingPage.ErrorRemovingLanguage')} - ${e.message}`)
    } finally {
      setSaveLoading(false)
      closeDeleteLanguageDialog()
    }
  }

  const openDeleteLanguageDialog = (languageId: string) => {
    setSelectedLanguageIdToDelete(languageId)
    setDeleteDialogOpen(true)
  }

  const closeDeleteLanguageDialog = () => {
    setSelectedLanguageIdToDelete(undefined)
    setDeleteDialogOpen(false)
  }

  const swapLanguageIndices = async (a: number, b: number) => {
    setSaveLoading(true)

    const { landingPageContents } = landingPage

    const ranks = landingPageContents.map(({ id }, i) => {
      if (i === a) {
        return { id, rank: b }
      }

      if (i === b) {
        return { id, rank: a }
      }

      return { id, rank: i }
    })

    try {
      await Promise.all(
        ranks.map(({ id, rank }) =>
          patchUpdateLandingPageContents(id, { rank }),
        ),
      )
      revalidateLandingPage(landingPage.id)
    } catch (e) {
      toastError(`${t('LandingPage.ErrorReorderingLanguages')} - ${e.message}`)
    } finally {
      setSaveLoading(false)
    }
  }

  if (languagesLoading || !languageData?.languages?.length) {
    return null
  }

  return (
    <>
      <Dialog
        data-testid="Dialog-delete-confirmation-dialog"
        open={deleteDialogOpen}
        disableCloseButton
      >
        <DialogTitle description={t('LandingPage.DeleteLanguageMessage')}>
          {t('Home.Delete')}
        </DialogTitle>
        <DialogActions>
          <Button tertiary onClick={closeDeleteLanguageDialog}>
            {t('Home.Cancel')}
          </Button>
          <Button
            data-testid="Dialog-language-delete-button"
            danger
            onClick={handleDeleteLanguage}
          >
            {t('LandingPage.DeleteLanguageConfirmButton')}
          </Button>
        </DialogActions>
      </Dialog>

      {saveLoading && (
        <div
          style={{
            display: 'grid',
            justifyContent: 'center',
            alignContent: 'center',
          }}
        >
          <LoadingSpinner width="40px" />
        </div>
      )}

      <GridItem>
        <List>
          {landingPage.landingPageContents.map(
            ({ language }, languageIndex) => (
              <ListItem
                key={language.id}
                data-testid={`language-${language.id}`}
                role="listitem"
                secondaryItemText={
                  <Flex direction="row" gutterSpacing={2}>
                    <Button
                      secondary
                      disabled={languageIndex === 0 || saveLoading}
                      onClick={() => {
                        swapLanguageIndices(languageIndex, languageIndex - 1)
                      }}
                      title={`Move ${language.name} up`}
                    >
                      <ArrowUpIcon />
                    </Button>
                    <Button
                      secondary
                      disabled={
                        languageIndex ===
                          landingPage.landingPageContents.length - 1 ||
                        saveLoading
                      }
                      onClick={() => {
                        swapLanguageIndices(languageIndex, languageIndex + 1)
                      }}
                      title={`Move ${language.name} down`}
                    >
                      <ArrowDownIcon />
                    </Button>
                    <Button
                      data-testid={`delete-language-${language.id}`}
                      disabled={saveLoading}
                      onClick={() => openDeleteLanguageDialog(language.id)}
                      title={t('LandingPage.DeleteLanguageConfirmButton')}
                      danger
                    >
                      <DeleteIcon />
                    </Button>
                  </Flex>
                }
              >
                {language.name}
              </ListItem>
            ),
          )}
          <ListItem
            secondaryItemText={
              <Button onClick={onAddNewLanguage} disabled={saveLoading}>
                {t('LandingPage.Add')}
              </Button>
            }
          >
            <Dropdown
              data-testid="Dialog-add-language-dialog_dropdown"
              placeholder={t('LandingPage.SelectAnOption')}
              options={languageData.languages
                .filter(
                  language =>
                    !landingPage.landingPageContents.some(
                      content => content.language.id === language.id,
                    ),
                )
                .map(language => ({
                  label: language.name,
                  value: language.id,
                }))}
              onChange={e => setNewLanguage(e.target.value as string)}
              value={newLanguage}
            />
          </ListItem>
        </List>
      </GridItem>
    </>
  )
}
