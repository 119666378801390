import React from 'react'
import { Translation } from 'react-i18next'
import { toast } from 'react-toastify'

export const toastSuccess = title => {
  toast.configure()
  toast.success(
    <div data-testid="success-toast">
      <Translation>{t => t(`${title}`)}</Translation>
    </div>,
  )
}
