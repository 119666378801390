import { useRef, useEffect, useState } from 'react'

import { getFileFromUrl } from './getFileFromUrl'

export const usePrevious = <T>(value: T): T | null => {
  const ref = useRef(null as null | T)
  useEffect(() => {
    ref.current = value
  })
  return ref.current
}

export const useFileFromURL = (
  url: string,
  name: string,
  defaultFile?: File,
): [File, (file: File) => void] => {
  const [file, setFile] = useState(defaultFile)

  useEffect(() => {
    getFileFromUrl(url, name)
      .then(newFile => {
        setFile(newFile)
      })
      .catch(e => {
        console.error(e)
      })
  }, [url, name])

  return [file, setFile]
}
