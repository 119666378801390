import Compressor from 'compressorjs'

import { NewDodoFile } from '../global/types'

const MAX_IMAGE_DIMENSION = 800

export async function optimisedImage(image: NewDodoFile): Promise<NewDodoFile> {
  if (image.name.endsWith('.gif')) return image

  const compressedImage = await new Promise(resolve => {
    // eslint-disable-next-line no-new
    new Compressor(image.contents, {
      quality: 0.8,
      maxHeight: MAX_IMAGE_DIMENSION,
      maxWidth: MAX_IMAGE_DIMENSION,
      success: compressed => {
        resolve(compressed as File)
      },
    })
  })

  return {
    ...image,
    contents: compressedImage,
  } as NewDodoFile
}
