import { withPrefix } from 'gatsby'
import { v4 as uuid } from 'uuid'

const layoutBlock = ({
  container = true,
  item = false,
  name = '',
  direction = 'column',
  children = [],
  properties = {},
  responsive = false,
} = {}) => ({
  id: uuid(),
  name,
  type: 'layout',
  properties: {
    responsive,
    container,
    direction,
    item,
    ...properties,
  },
  children,
})

const textBlock = ({
  value = 'Click here to edit',
  name = '',
  properties = {},
} = {}) => ({
  id: uuid(),
  type: 'text',
  name,
  value,
  properties,
})

const imageBlock = ({
  value = withPrefix('/common/upload.png'),
  name = '',
  properties = { loading: 'lazy' },
} = {}) => ({
  id: uuid(),
  name,
  type: 'image',
  value,
  properties,
})

const row1 = () =>
  layoutBlock({
    name: 'row1',
    responsive: true,
    properties: {
      spacing: 2,
      style: {
        width: '100%',
        marginBottom: 100,
      },
    },
    children: [
      layoutBlock({
        item: true,
        properties: {
          xs: 10,
          sm: 5,
        },
        children: [
          textBlock({
            value: 'Hallo',
            properties: {
              sectionTitle: true,
              style: {
                marginBottom: 15,
                fontSize: '1.75rem',
              },
            },
          }),
          textBlock({
            properties: {
              paragraph: true,
              style: {
                marginBottom: 75,
                fontSize: '1.25rem',
              },
            },
          }),
          imageBlock({
            properties: {
              style: {
                display: 'none',
              },
              desktop: {
                style: {
                  display: 'block',
                },
              },
              loading: 'lazy',
            },
          }),
        ],
      }),
      layoutBlock({
        item: true,
        children: [textBlock({ value: '$FORM' })],
        properties: {
          xs: 12,
          sm: 5,
          style: {
            padding: '40px 60px',
            backgroundColor: '$PRIMARY_COLOR',
            justifyContent: 'center',
            width: '100%',
          },
        },
      }),
    ],
  })

const row2 = () =>
  layoutBlock({
    name: 'row2',
    responsive: true,
    properties: {
      spacing: 2,
      style: {
        marginBottom: 100,
        width: '100%',
      },
    },
    children: [
      layoutBlock({
        item: true,
        properties: {
          xs: 10,
          sm: 5,
          desktop: {
            style: {
              display: 'flex',
              'flex-direction': 'column',
              'justify-content': 'center',
            },
          },
        },
        children: [
          textBlock({
            properties: {
              sectionTitle: true,
              style: {
                marginBottom: 15,
                fontSize: '1.75rem',
                lineHeight: '2rem',
              },
            },
          }),
          textBlock({
            properties: {
              paragraph: true,
              style: {
                fontSize: '1.25rem',
                lineHeight: '1.5',
              },
            },
          }),
        ],
      }),
      layoutBlock({
        item: true,
        properties: {
          xs: 10,
          sm: 5,
        },
        children: [imageBlock()],
      }),
    ],
  })

const row3 = () =>
  layoutBlock({
    name: 'row2',
    responsive: true,
    properties: {
      spacing: 2,
      style: {
        marginBottom: 100,
        width: '100%',
      },
      desktop: {
        style: {
          'flex-direction': 'row-reverse',
        },
      },
    },
    children: [
      layoutBlock({
        item: true,
        properties: {
          xs: 10,
          sm: 5,
          desktop: {
            style: {
              display: 'flex',
              'flex-direction': 'column',
              'justify-content': 'center',
            },
          },
        },
        children: [
          textBlock({
            properties: {
              sectionTitle: true,
              style: { fontSize: '1.75rem', marginBottom: 15 },
            },
          }),
          textBlock({
            properties: {
              paragraph: true,
              style: {
                fontSize: '1.25rem',
                lineHeight: '1.5',
              },
            },
          }),
        ],
      }),
      layoutBlock({
        item: true,
        properties: {
          xs: 10,
          sm: 5,
        },
        children: [imageBlock()],
      }),
    ],
  })

const threeBlocksInColumn = () =>
  layoutBlock({
    properties: {
      sm: 3,
      style: {
        fontSize: '1.1rem',
        marginBottom: 70,
      },
    },
    item: true,
    children: [
      imageBlock({
        properties: {
          style: {
            width: 72,
          },
          loading: 'lazy',
        },
      }),
      textBlock({
        value: 'Value 1',
        properties: {
          style: {
            marginBottom: 10,
            fontSize: '1.25rem',
          },
        },
      }),
      textBlock({
        value: 'Value 2',
        properties: {
          paragraph: true,
        },
      }),
    ],
  })

const row4 = () =>
  layoutBlock({
    name: 'row4',
    item: true,
    properties: {
      justify: 'center',
      align: 'center',
      style: {
        marginBottom: 100,
      },
    },
    children: [
      textBlock({
        properties: {
          sectionTitle: true,
          style: {
            marginBottom: 62,
            fontSize: '1.75rem',
          },
        },
      }),
      layoutBlock({
        responsive: true,
        children: [
          threeBlocksInColumn(),
          threeBlocksInColumn(),
          threeBlocksInColumn(),
        ],
      }),
    ],
  })

const row5 = () =>
  layoutBlock({
    name: 'row5',
    properties: {
      spacing: 2,
      style: {
        width: '100%',
        backgroundColor: '$PRIMARY_COLOR',
        marginBottom: 100,
        paddingTop: 33,
        paddingBottom: 33,
      },
    },
    children: [
      layoutBlock({
        item: true,
        properties: {
          xs: 10,
        },
        children: [
          textBlock({
            properties: {
              sectionTitle: true,
              style: {
                color: '#fff',
                fontSize: '1.75rem',
              },
            },
          }),
          textBlock({
            properties: {
              paragraph: true,
              style: {
                color: '#fff',
                fontSize: '1.1rem',
              },
            },
          }),
        ],
      }),
    ],
  })

const twoBlocksRow = () =>
  layoutBlock({
    properties: {
      sm: 6,
      style: {
        marginBottom: 48,
      },
    },
    item: true,
    children: [
      imageBlock({
        properties: {
          style: {
            width: 72,
          },
        },
      }),
      textBlock({
        value: 'Value 1',
        properties: {
          style: {
            marginBottom: 10,
            fontSize: '1.5rem',
            lineHeight: '2',
          },
        },
      }),
      textBlock({
        value: 'Value 2',
        properties: {
          paragraph: true,
          style: {
            fontSize: '1.1rem',
            maxWidth: '250px',
            lineHeight: '2',
          },
        },
      }),
    ],
  })

const row6 = () =>
  layoutBlock({
    name: 'row6',
    item: true,
    properties: {
      justify: 'center',
      align: 'center',
      style: {
        marginBottom: 100,
      },
    },
    children: [
      textBlock({
        properties: {
          sectionTitle: true,
          style: {
            marginBottom: 62,
            fontSize: '1.75rem',
          },
        },
      }),
      layoutBlock({
        responsive: true,
        children: [twoBlocksRow(), twoBlocksRow()],
        properties: { style: { padding: '0 10%' } },
      }),
    ],
  })

const row7 = () =>
  layoutBlock({
    name: 'row7',
    item: true,
    properties: {
      align: 'center',
      style: {
        paddingBottom: 100,
      },
    },
    children: [
      imageBlock({
        properties: {
          style: {
            borderRadius: 0,
          },
        },
      }),
      textBlock({
        properties: {
          sectionTitle: true,
          style: {
            fontSize: '1.75rem',
            lineHeight: '2rem',
          },
        },
      }),
      textBlock({
        properties: {
          paragraph: true,
          style: {
            fontSize: '1.25rem',
          },
        },
      }),
    ],
  })

const header = () =>
  layoutBlock({
    name: 'header',
    properties: {
      style: { width: '100%' },
    },
    children: [
      textBlock({
        properties: {
          style: {
            width: '100%',
          },
        },
        value: '$HEADER',
      }),
    ],
  })

export const landingPageTemplate = {
  version: 1,
  structure: layoutBlock({
    name: 'root',
    properties: {},
    children: [
      header(),
      row1(),
      row2(),
      row3(),
      row4(),
      row5(),
      row6(),
      row7(),
    ],
  }),
}
