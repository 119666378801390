import { Link } from 'gatsby'
import React from 'react'

import { Container, Card, styled, Pack, PackItem } from '@deliveryhero/armor'

import { prefixUrlIfRelative } from '../../utils/prefixUrlIfRelative'
import mountainsBackground from '../home/images/mountains.svg'
import sunBackground from '../home/images/sun.svg'
import { useGetThemes } from '../landing-page/api'
import { DodoIcon } from '../navbar/DodoIcon'

const ContainerWithBackground = styled.div`
  background: url(${mountainsBackground}) no-repeat bottom/100% fixed,
    url(${sunBackground}) right top no-repeat;
`

const ThemeCard = styled(Card)`
  width: 312px;
  height: 216px;
  display: flex;
  justify-content: center;
  align-items: center;
  box-shadow: 0px 2px 28px 0px #0000001f;

  border-radius: 12px;
`

const CustomPack = styled(Pack)`
  gap: 24px;
`

export const Themes: () => JSX.Element = () => {
  const { data: themes, loading, error } = useGetThemes()

  if (loading) return <h1>Loading...</h1>

  if (error) return <h1>Error...</h1>

  return (
    <ContainerWithBackground>
      <Container maxWidth={900} padding="60px" paddingTop="53px">
        <CustomPack flexWrap="wrap">
          {Object.entries(themes).map(([id, theme]) => (
            <PackItem key={id}>
              <Link to={`/app/themes/${id}`}>
                <ThemeCard key={id} padding={3} data-testid="theme-container">
                  {theme.logo ? (
                    <img
                      src={prefixUrlIfRelative(theme.logo)}
                      alt={theme.name}
                      style={{ maxWidth: '100%', maxHeight: '100%' }}
                    />
                  ) : (
                    <DodoIcon width={150} />
                  )}
                </ThemeCard>
              </Link>
            </PackItem>
          ))}
        </CustomPack>
      </Container>
    </ContainerWithBackground>
  )
}
