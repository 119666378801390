import React, { useContext, useState } from 'react'
import { ToastContainer } from 'react-toastify'

import { styled } from '@deliveryhero/armor'

import { LoadingSpinner } from '../../components/Atoms/LoadingSpinner'
import SEO from '../../components/seo'
import { GlobalContext } from '../../global/Context'

import { CreateLandingPageDialog } from './CreateLandingPageDialog'
import { CreateLandingPageSplash } from './CreateLandingPageSplash'
import { LandingPagesHomeGrid } from './LandingPagesHomeGrid'
import { useGetAllLandingPages } from './api'

import 'react-toastify/dist/ReactToastify.min.css'

const LoadingContainer = styled.div`
  padding: 2rem;
  height: 100%;
  display: grid;
  justify-content: center;
  align-content: center;
`

const HomePageGrid = ({
  createDisabled,
  handleCreate,
}: {
  createDisabled: boolean
  handleCreate: () => void
}) => {
  const { data, loading } = useGetAllLandingPages()

  if (loading) {
    return (
      <LoadingContainer>
        <LoadingSpinner width="50px" />
      </LoadingContainer>
    )
  }

  if (data?.landing_pages != null) {
    return (
      <LandingPagesHomeGrid
        landingPages={data.landing_pages}
        createDisabled={createDisabled}
        handleCreate={handleCreate}
      />
    )
  }

  return (
    <CreateLandingPageSplash
      handleCreate={handleCreate}
      createDisabled={createDisabled}
    />
  )
}

const IndexPage: React.FC = () => {
  const { selectedCountryCode } = useContext(GlobalContext)

  const [isModalOpen, setIsModalOpen] = useState(false)

  const createDisabled = selectedCountryCode == null

  const handleCreate = () => {
    setIsModalOpen(true)
  }

  const closeModal = () => {
    setIsModalOpen(false)
  }

  return (
    <>
      <SEO title="Home" />

      <ToastContainer />

      <HomePageGrid
        createDisabled={createDisabled}
        handleCreate={handleCreate}
      />

      <CreateLandingPageDialog
        isModalOpen={isModalOpen}
        closeModal={closeModal}
        selectedCountryCode={selectedCountryCode}
      />
    </>
  )
}

export default IndexPage
