import React from 'react'

import { Typography, Tab, Grid } from '@deliveryhero/armor'

import { TabView } from '../../../components/armor-overrides'
import { Language } from '../../../global/types'

import { CenteredTabs } from './shared-components'

type IProps = {
  languages: Language[]
  selectedLanguage: number
  onSelectLanguage: (selectedLanguage: number) => void
  render: (selectedLanguage: number) => React.ReactElement
}

export const LanguageTabs: React.FC<IProps> = ({
  languages,
  selectedLanguage,
  onSelectLanguage,
  render,
}) => (
  <section data-testid="language-tabs">
    <CenteredTabs defaultActiveTab={selectedLanguage}>
      {languages.map((_, index) => (
        <Tab
          onClick={() => {
            onSelectLanguage(index)
          }}
        >
          <Typography>{languages[index].name}</Typography>
        </Tab>
      ))}
    </CenteredTabs>

    {languages.map((_, index) => (
      <TabView value={index} selectedValue={selectedLanguage}>
        <Grid>{render(selectedLanguage)}</Grid>
      </TabView>
    ))}
  </section>
)
