import { KeyedResponseData } from '../global/types'

// This function accepts PATCH objects to merge with the current changes
export const mergeNewChanges = (
  changes: KeyedResponseData,
  newChanges: KeyedResponseData,
) => {
  let mergedChanges = changes

  Object.keys(newChanges).forEach(resourceName => {
    // Merge old changes with new ones
    const newResource = Object.keys(newChanges[resourceName]).reduce(
      (mergedResource, id) => {
        // If the object already exists in the store... then just merge the new information
        if (mergedResource && mergedResource[id]) {
          return {
            ...mergedResource,
            ...{
              [id]: {
                ...mergedResource[id],
                ...newChanges[resourceName][id],
              },
            },
          }
        }

        // The new change does not exist so we insert it to the list of changes and we might need to also create the resource key first
        return {
          // If resource is undefined (There's no changes for this type of resource yet, then just create an empty object)
          ...(!mergedResource ? {} : mergedResource),
          // Finally just add the information for this resource item
          ...{ [id]: newChanges[resourceName][id] },
        }
      },
      changes[resourceName],
    )

    mergedChanges = {
      ...mergedChanges,
      [resourceName]: newResource,
    }
  })

  return mergedChanges
}
