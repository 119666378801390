import { styled, Typography, Grid } from '@deliveryhero/armor'

import { Tabs } from '../../../components/armor-overrides'

const CenteredTabs = styled(Tabs)`
  margin: auto;
  margin-bottom: 1.5rem;
`

const GridHeader = styled.div`
  color: #717171;
  font-size: 0.85rem;
  font-weight: 500;
  margin-bottom: 1rem;
  width: 100%;
`

const RowAlignedGrid = styled(Grid)`
  flex-direction: row;
  justify-content: space-between;
  width: 100%;
`

const Title = styled(Typography)`
  margin: unset;
`

const Description = styled.section`
  margin-left: 0.5rem;
`

const DescriptionTitle = styled(Title)`
  color: #717171;
  font-size: 1rem;
  margin-top: 2rem;
`

const DescriptionBody = styled(Typography)`
  margin-bottom: 1.5rem;
  color: #717171;
  width: 100%;
`

const DescriptionNote = styled(DescriptionBody)`
  margin-left: 0.5rem;
`

export {
  CenteredTabs,
  GridHeader,
  RowAlignedGrid,
  Title,
  Description,
  DescriptionTitle,
  DescriptionBody,
  DescriptionNote,
}
