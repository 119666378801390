import { Field } from '../../../global/types'

export const createLandingPageField = (properties = {}): Field => ({
  name: '',
  text: '',
  settings: {},
  enabled: true,
  fieldType: 'string',
  orderIndex: 0,

  ...properties,
})
